import styled from 'styled-components'
import * as styles from '../styles'
import CoolImg from 'react-cool-img'
import NextImage from 'next/image'

// Suggest to use low quality or vector images

export const Image: any = styled(CoolImg)`
  width: 100%;
  height: auto;
  /* -webkit-box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75);
  box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75); */
  align-self: center;
  @media (max-width: ${styles.GRID_SM}px) {
    align-self: center;
  }
`

export const Box = styled.div`
  padding: ${styles.PADDING_XL};
  border: 1px solid ${styles.DARK3};
`
export const Img: any = styled(NextImage)`
  /* -webkit-box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75);
  box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75); */
  align-self: center;
  @media (max-width: ${styles.GRID_SM}px) {
    align-self: center;
  }
`

export const FitImage: any = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 400px;
  /* -webkit-box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75);
  box-shadow: -7px 10px 23px -13px rgba(0, 0, 0, 0.75); */
  align-self: center;
  @media (max-width: ${styles.GRID_SM}px) {
    align-self: center;
  }
`

export const dropShadow = 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.2))'

export const mintbaseLogoSingleDark =
  'https://firebasestorage.googleapis.com/v0/b/thing-1d2be.appspot.com/o/web%2Flogo-dark_700x700.png?alt=media&token=e2c7455f-a899-42d9-b573-53a61cb9bc6b'

export const mintbaseLogoSingleWhite =
  'https://firebasestorage.googleapis.com/v0/b/thing-1d2be.appspot.com/o/web%2Flogo-white_700x700.png?alt=media&token=777a0487-33c9-47e7-b6be-edd7a1740017'
