import { connect, keyStores, Contract, WalletAccount, utils } from 'near-api-js'
import getConfig from './nearConfig'
import { StoreContract } from 'components/AppContext'
import { FinalExecutionOutcome } from 'near-api-js/lib/providers'
const nearConfig = getConfig(process.env.NODE_ENV || 'development')

const NAME = 'mintbase'

export const getStoreContract = async (
  contract: string,
  nearConnection: any
): Promise<StoreContract> => {
  const contractName = contract

  const storeContract = new Contract(nearConnection.account(), contractName, {
    viewMethods: [
      'check_access',
      'get_token_owner',
      'get_name',
      'get_marketplace',
    ],
    changeMethods: [
      'nft_batch_mint',
      'grant_access',
      'revoke_access',
      'transfer_from',
      'nft_batch_burn',
      'nft_batch_transfer',
      'grant_minter',
      'renounce_minter',
      'set_icon_base64',
      'transfer',
      'set_base_uri',
      'new',
      'destroy',
      'list_tokens',
      'set_marketplace',
    ],
  })
  //@ts-ignore
  return storeContract
}

const getNearConnection = async () => {
  return await connect(
    Object.assign(
      { deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } },
      nearConfig
    )
  )
}

const getWalletDetails = async (account: any) => {
  const keyPair = await getSessionKeyPair(account.accountId)

  if (!keyPair) return

  const publicKey = keyPair.getPublicKey().toString()

  const balance = await account.getAccountBalance()

  const accessKey = await viewAccessKey({
    account: account.accountId,
    publicKey: publicKey,
  })

  const allowance = utils.format.formatNearAmount(
    accessKey.permission.FunctionCall.allowance
  )

  return {
    balance: utils.format.formatNearAmount(balance.total, 2),
    allowance: allowance,
  }
}

export const connectNEAR = async (required: boolean) => {
  const near = await getNearConnection()

  const wallet = new WalletAccount(near, NAME)

  if (required) {
    await wallet.requestSignIn(nearConfig.contractName, 'Welcome to Mintbase')
  }

  const accountId = wallet.getAccountId() !== '' ? wallet.getAccountId() : null

  const account = wallet.account()

  const walletDetails = await getWalletDetails(account)

  return {
    nearConnection: wallet,
    account: accountId,
    walletDetails,
  }
}

export const newAccount = async () => {
  const near = await getNearConnection()

  localStorage.removeItem(`${NAME}_wallet_auth_key`)

  const connection = new WalletAccount(near, NAME)

  const account =
    connection.getAccountId() !== '' ? connection.getAccountId() : null

  await connection.requestSignIn(nearConfig.contractName, 'Welcome to Mintbase')

  return {
    nearConnection: connection,
    account: account,
  }
}

export const switchAccount = async (account: string) => {
  const near = await getNearConnection()

  const keyStore = new keyStores.BrowserLocalStorageKeyStore()

  const privateKey = await keyStore.getKey(nearConfig.networkId, account)

  if (!privateKey) {
    await newAccount()
    return
  }

  await keyStore.setKey(nearConfig.networkId, account, privateKey)

  localStorage.setItem(
    `${NAME}_wallet_auth_key`,
    JSON.stringify({ accountId: account, allKeys: [privateKey] })
  )

  const wallet = new WalletAccount(near, NAME)

  const walletDetails = await getWalletDetails(wallet.account())

  return {
    nearConnection: wallet,
    account: wallet.getAccountId(),
    walletDetails,
  }
}

const getSessionKeyPair = async (account: string) => {
  const keyStore = new keyStores.BrowserLocalStorageKeyStore()

  const key = await keyStore.getKey(nearConfig.networkId, account)

  return key
}

export const getLocalStorageAccounts = async () => {
  const near = await getNearConnection()
  const keyStore = new keyStores.BrowserLocalStorageKeyStore()

  const walletAccount = new WalletAccount(near, NAME)

  const account =
    walletAccount.getAccountId() !== '' ? walletAccount.getAccountId() : null

  const accounts = keyStore.getAccounts(nearConfig.networkId)

  return accounts
}

const rpcRequest = async ({
  headers = {},
  body = {},
}: {
  headers?: any
  body?: any
}) => {
  const request = await fetch(nearConfig.nodeUrl, {
    method: 'POST',
    body: JSON.stringify({
      ...body,
      jsonrpc: '2.0',
      id: `mintbase-${Math.random().toString(20).substr(2, 10)}`,
      method: 'query',
    }),
    headers: {
      ...headers,
      'Content-type': 'application/json',
    },
  })

  const data = await request.json()

  return data?.result
}

export const viewAccessKey = async ({
  account,
  publicKey,
}: {
  account: string
  publicKey: string
}) => {
  const result = await rpcRequest({
    body: {
      params: {
        request_type: 'view_access_key',
        finality: 'final',
        account_id: account,
        public_key: publicKey,
      },
    },
  })

  return result
}

export const viewAccessKeyList = async ({ account }: { account: string }) => {
  const result = await rpcRequest({
    body: {
      params: {
        request_type: 'view_access_key_list',
        finality: 'final',
        account_id: account,
      },
    },
  })

  return result
}

export const fetchTransactionLastResult = async (
  txHash: string,
  account: string
): Promise<FinalExecutionOutcome> => {
  const near = await getNearConnection()

  const decodedHash = utils.serialize.base_decode(txHash)

  const result = await near.connection.provider.txStatus(decodedHash, account)

  return result
}
