import { useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import * as paths from 'content/paths'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
export const QUERY_LIMIT = 12

let apolloClient

export const getUri = (headers) => {
  if (!headers || !headers.host) {
    return paths.GRAPH_TESTNET
  }
  const subdomain = headers.host.split('.')

  if (
    subdomain[0] === 'testnet' ||
    subdomain[0] === 'near' ||
    subdomain[0] === 'localhost:3000'
  ) {
    return paths.GRAPH_TESTNET
  }

  return paths.GRAPH_MAINNET
}

function createApolloClient(graphUri: string) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: graphUri, //paths.GRAPH_TESTNET, // Server URL (must be absolute)
      credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
      headers: {
        'x-hasura-role': 'anonymous',
      },
    }),
    cache: new InMemoryCache(),
  })
}

export function initializeApollo(initialState = null, graphUri: string) {
  const _apolloClient = apolloClient ?? createApolloClient(graphUri)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo(state, pageProps?.network?.graphUri),
    [state]
  )
  return store
}
