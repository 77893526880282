import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import { log } from 'console'
import { logDOM } from '@testing-library/react'

export const FieldValue = firebase.firestore.FieldValue

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIRSTORE_BUCKET_URL,
}

// export const initFirebase = () => {
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
// }

export const database = firebase.firestore()
export const storage = firebase.storage()
export const auth = firebase.auth()

// initFirebase()
// export default firebase

// const firebaseAuthDomain = 'omni-base-1.firebaseapp.com'
// const firebaseDatabaseUrl = 'https://omni-base-1.firebaseio.com'

// const firebaseProjectId = 'omni-base-1'
// const firebaseBucket = 'omni-base-1.appspot.com'

// const authEmail = 'support@mintbase.io'
// // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)

// const configs = {
//   apiKey: 'AIzaSyCpKk5iwkyu4Gm4hPS93k5w-C45-5QsA2U', //process.env.FIREBASE_API_KEY,
//   authDomain: firebaseAuthDomain,
//   databaseURL: firebaseDatabaseUrl,
//   projectId: firebaseProjectId,
//   storageBucket: firebaseBucket,
//   appId: process.env.FIREBASE_API_KEY,
// }

// // firebase.initializeApp(configs)

// if (!firebase.apps.length) {
//   firebase.initializeApp(configs)

//   // firebase
//   //   .auth()
//   //   .signInWithEmailAndPassword(
//   //     process.env.authEmail || '',
//   //     process.env.FIREBASE_EMAIL_PASS || ''
//   //   )
//   //   .catch(function (error) {
//   //     // Handle Errors here.
//   //     var errorCode = error.code
//   //     var errorMessage = error.message
//   //     // ...
//   //   })
// }

// export const database = firebase.firestore()
// export const storage = firebase.storage()
// export const auth = firebase.auth()
// export default firebase
// // export
