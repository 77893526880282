import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import { Search as MagnifyingGlassIcon } from '@styled-icons/feather/Search'
import { Close as CloseIcon } from '@styled-icons/evil/Close'
import { useApp } from './AppContext'
import useDebounce from './useDebounce'
import { useRouter } from 'next/router'

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 ${styles.PADDING_SM};
  align-items: center;
`
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`

const DesktopView = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${styles.GRID_SM}px) {
    display: none;
  }
`

const MobileView = styled.div`
  display: none;
  @media (max-width: ${styles.GRID_SM}px) {
    display: flex;
    flex: 1;
  }
`

export const DarkInput = styled.input<{
  right?: string
  bottom?: string
  top?: string
}>`
  border: 0;
  font-size: 1.1em;
  display: flex;
  flex-grow: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  background: none;
  color: ${({ theme, color }) => color || theme.DARK1};
  padding: ${styles.PADDING_SM};
  margin-top: ${({ top }) => top || styles.PADDING_SM};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_SM};
  margin-right: ${({ right }) => right || 0};
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.DARK2};
    font-style: italic;
    opacity: 1; /* Firefox */
    font-size: 1em;
  }
`

interface Props {
  onMobileSearchToggle: (isEnabled: boolean) => void
}

const getSearchParam = (path: string) => {
  if (path.startsWith('/search/')) {
    return path.split('/search/')[1]
  }
  return ''
}

const SearchBar: React.FC<Props> = ({ onMobileSearchToggle }) => {
  const { colors } = useApp()

  const router = useRouter()
  const searchParam = getSearchParam(router.asPath)
  const [fromPath, setFromPath] = useState('')
  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false)
  const [searchText, setSearchText] = useState(searchParam)

  const debouncedSearchTerm = useDebounce(searchText, 500)

  const hasFromPath = fromPath !== ''

  useEffect(() => {
    if (searchText !== '') {
      router.push(`/search/${searchText}`)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (router.asPath.startsWith('/search/')) return

    setFromPath(router.asPath)
  }, [router.asPath])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    updateInput(value)
  }

  const handleRoutingBack = (value: string) => {
    const shouldGoBackToPreviousPage = value === '' && searchText !== ''
    if (shouldGoBackToPreviousPage && hasFromPath) router.push(fromPath)
  }

  const updateInput = (value: string) => {
    handleRoutingBack(value)
    setSearchText(value)
  }

  const handleMobileSearchToggle = () => {
    const nextState = !isMobileSearchActive

    setIsMobileSearchActive(nextState)
    onMobileSearchToggle(nextState)

    updateInput('')
  }

  return (
    <Container>
      <DesktopView>
        <MagnifyingGlassIcon width={30} height={30} color={colors.DARK2} />
        <DarkInput
          placeholder="Store Search"
          value={searchText}
          onChange={handleInputChange}
        />
      </DesktopView>
      <MobileView>
        {isMobileSearchActive ? (
          <Wrapper>
            <DarkInput
              placeholder="Store Search"
              value={searchText}
              onChange={handleInputChange}
            />
            <Wrapper onClick={handleMobileSearchToggle}>
              <CloseIcon color={colors.DARK1} width={33} />
            </Wrapper>
          </Wrapper>
        ) : (
          <Wrapper onClick={handleMobileSearchToggle}>
            <MagnifyingGlassIcon color={colors.DARK1} width={33} />
          </Wrapper>
        )}
      </MobileView>
    </Container>
  )
}

export default SearchBar
