import React, { useState, useEffect } from 'react'
import { Contract } from 'near-api-js'
import { useSpring } from 'react-spring'
import styled from 'styled-components'
import {
  useApp,
  useDispatchApp,
  SET_ERROR,
  TOGGLE_NEW_STORE_OPEN,
  SET_WALLET_CONNECT,
} from './AppContext'
import * as styles from '../styles'
import { Header1, Subheader1, Input, Copy } from './Typography'
import * as paths from 'content/paths'
import { WideButton } from './Buttons'
import { Container, FormContainer, CloseButton } from './TransferModal'
import BN from 'bn.js'
import getConfig from 'utils/nearConfig'

const GAS = new BN('100000000000000')
const OneNear = new BN('1000000000000000000000000')
const nearConfig = getConfig(process.env.NODE_ENV || 'development')
// @ts-ignore
const fromYocto = (a) => Math.floor((a / OneNear) * 1000) / 1000

export const MintButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

const CreateStore = () => {
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')

  const dispatch = useDispatchApp()
  const {
    newStoreOpen,
    storeCreationPending,
    screenWidth,
    account,
    nearConnection,
  } = useApp()

  const checkLength = (event: any) => {
    const txt = event.target.value
    if (txt.length <= 4) {
      setSymbol(txt.toUpperCase())
    }
  }

  const [factoryContract, setFactoryContract] = useState(null)

  const initFactory = async (contract: any) => {
    // const init = await contract.new();
    // const owner = await contract.get_owner();
    // console.log("init@!!!!!!!", owner);
  }

  useEffect(() => {
    if (nearConnection && nearConnection.account) {
      const deal = new Contract(
        nearConnection.account(),
        nearConfig.contractName,
        {
          // eslint-disable-line require-atomic-updates
          // View methods are read only. They don't modify the state, but usually return some value.
          viewMethods: [
            'get_min_attached_balance',
            'get_number_of_tokens',
            'get_store_descriptions',
            'get_token_description',
            'get_owner',
            'get_mintbase_fee',
          ],
          changeMethods: [
            'create_store',
            'set_mintbase_fee',
            'transfer_ownership',
            'new',
          ],
        }
      )

      // initFactory(deal);
      setFactoryContract(deal)
    }
  }, [nearConnection])

  const handleSubmit = async () => {
    const item = {
      store_description: {
        token_id: name,
        owner_id: account,
        description: symbol,
        icon_base64: 'eeieieieie',
        base_uri: paths.BASE_ARWEAVE_URI,
      },
    }

    if (factoryContract) {
      const bal = await factoryContract.get_min_attached_balance()

      try {
        const store = await factoryContract.create_store(item, GAS, bal)
      } catch (error) {}
      return
    }

    dispatch({
      type: SET_ERROR,
      payload: 'Near connection not found',
    })

    return
  }

  useEffect(() => {
    // console.log("factoryContract-------", factoryContract);
    if (factoryContract) {
      initFactory(factoryContract)
    }
  }, [factoryContract])

  const onChange = (func: any) => (event: any) => func(event.target.value)

  // const styleProps = useSpring({
  //   transform: newStoreOpen ? `scaleX(1)` : `scaleX(0)`,
  // });
  const styleProps = useSpring({
    transform: newStoreOpen
      ? `translate(0px, 0px)`
      : `translate(${-screenWidth}px, 0px)`,
    opacity: newStoreOpen ? 1 : 0,
  })

  useEffect(() => {
    if (newStoreOpen) {
      dispatch({
        type: SET_WALLET_CONNECT,
        payload: { contract: null, needsWallet: true },
      })
    }
  }, [newStoreOpen])

  return (
    <Container style={styleProps}>
      <FormContainer>
        <CloseButton onClick={() => dispatch({ type: TOGGLE_NEW_STORE_OPEN })}>
          <styles.CloseX size={40} />
        </CloseButton>
        {!storeCreationPending && (
          <FormContent>
            <Header1>Create Store</Header1>
            <Subheader1>Your new token foundation</Subheader1>
            <Input
              placeholder="Name"
              value={name}
              onChange={onChange(setName)}
              color="primary"
            />
            <Input
              placeholder="Token/Ticker Symbol, Example: Ether = ETH"
              value={symbol}
              onChange={(e) => checkLength(e)}
            />
          </FormContent>
        )}
        {storeCreationPending && (
          <FormContent>
            <Header1>Neat! Pending Creation 🌚</Header1>
            <Subheader1>
              You'll be redirected once the blockchain confirms
            </Subheader1>
            <Copy>
              Once confirmed, a new tab will appear called "Builder". This is
              where you’ll create all your NFTs.
            </Copy>
          </FormContent>
        )}

        {!storeCreationPending && (
          <MintButtons>
            <WideButton onClick={() => handleSubmit()}>Create Store</WideButton>
          </MintButtons>
        )}
      </FormContainer>
    </Container>
  )
}

export default CreateStore
