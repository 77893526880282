import React from 'react'
import firebase from 'firebase/app'
import { Wallet, Chain, Network } from 'mintbase'
import { useApp, useDispatchApp, SET_USER } from 'components/AppContext'
import { baseUser, UIMode, User } from 'types/base'
import { database } from 'services/db'
import * as services from 'services'
import { setUserCookie } from './userCookies'

const FUNCTIONS_ENDPOINT = process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_URL

enum AuthState {
  IDLE = 'idle',
  WORKING = 'working',
}

interface SignedMessageData {
  publicKey: number[]
  signature: number[]
  accountId: string
  publicKey_str: string
  message: string
}

const useFirebaseCustomAuth = () => {
  const { user, wallet } = useApp()
  const dispatch = useDispatchApp()

  const [isSigned, setIsSigned] = React.useState(false)
  const [authState, setAuthState] = React.useState(AuthState.IDLE)
  const [authError, setAuthError] = React.useState('')

  const { currentUser } = firebase.auth()

  // TODO: this is a hotfix, remove asap
  const clearOldShit = async () => {
    const migration = localStorage.getItem('migration__29062021')
    const isMigrated = migration === '1'

    if (!isMigrated) {
      const firebaseAuth = firebase.auth();

      if (firebaseAuth) await firebaseAuth.signOut();

      indexedDB.deleteDatabase('firebaseLocalStorageDb')
      localStorage.clear()

      document.cookie = `auth=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`
      document.cookie = `admin=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`

      localStorage.setItem('migration__29062021', '1')
    }

  }

  React.useEffect(() => {
    clearOldShit()
  }, [])

  React.useEffect(() => {
    if (
      !currentUser ||
      authState !== AuthState.IDLE ||
      (user && user.docId === currentUser.uid) ||
      authError
    )
      return

    setUserData()
  }, [currentUser, authState])

  const getCustomToken = async (
    signedMessage: SignedMessageData,
    network: Network
  ) => {
    const {
      publicKey,
      signature,
      accountId,
      publicKey_str,
      message,
    } = signedMessage

    const result = await fetch(`${FUNCTIONS_ENDPOINT}/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        publicKey,
        publicKey_str,
        signature,
        accountId,
        message,
        chain: Chain.near,
        network,
      }),
    })

    if (result.status !== 200) {
      setAuthError('failed to get custom token')
      return null
    }
    const data = await result.json()
    return data.token
  }

  const getUserData = async () => {
    const userDoc = await database
      .collection(services.USER)
      .doc(currentUser.uid)
      .get()

    return !userDoc || !userDoc.exists ? createUserData() : userDoc.data()
  }

  const createUserData = async () => {
    try {
      const userId = `${wallet.chain}:${wallet.networkName}:${wallet.activeAccount.accountId}`
      const userData = {
        ...baseUser,
        id: userId,
        docId: currentUser.uid,
        walletAddress: wallet.activeAccount.accountId,
        username: wallet.activeAccount.accountId,
        network: wallet.networkName,
        chain: wallet.chain,
        createdAt: Date.now(),
      }

      await database
        .collection(services.USER)
        .doc(currentUser.uid)
        .set(userData)
      return userData
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  const setUserData = async () => {
    setAuthState(AuthState.WORKING)

    const userData = await getUserData()

    setAuthState(AuthState.IDLE)
    setUserCookie(userData)
    return dispatch({ type: SET_USER, payload: userData })
  }

  const signIn = async (wallet: Wallet, network: Network) => {
    if (!wallet || authState === AuthState.WORKING) return

    setAuthState(AuthState.WORKING)

    if (currentUser) setIsSigned(true)

    const message = JSON.stringify({ message: 'signing in' })
    const { data: signedData, error: signedError } = await wallet.signMessage(
      message
    )

    if (signedError) {
      setAuthError(signedError)
      return setIsSigned(false)
    }

    const signedMessage = {
      ...signedData,
      message,
    }

    const token = await getCustomToken(signedMessage, network)

    try {
      if (!token) throw 'failed to get custom token'

      await firebase.auth().signInWithCustomToken(token)

      setIsSigned(true)
      setAuthState(AuthState.IDLE)
    } catch (error) {
      setIsSigned(false)
      setAuthState(AuthState.IDLE)
      setAuthError(error)
    }
  }

  return {
    signIn,
    isSigned,
    loading: authState === AuthState.WORKING,
    error: authError,
  }
}

export default useFirebaseCustomAuth
