import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as styles from '../styles'
import { isEmpty } from 'lodash'
import TextCheck from 'components/TextCheck'
import { useSpring, animated } from 'react-spring'
import { Header1, Subheader1 } from './Typography'
import { useApp, useDispatchApp, SET_ERROR, SET_TRANSFER } from './AppContext'
import { Close } from '@styled-icons/evil/Close'
import { WideButton } from './Buttons'

const MintButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

export type TransferTheseThings = any

export const Container = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.DARK_ALPHA};
  z-index: 6;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35em;
  background-color: ${({ theme }) => theme.DARKER};
  position: relative;
  filter: drop-shadow(-2px 2px 10px rgba(0, 0, 0, 0.3));
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    max-height: 89vh;
    overflow-y: hidden;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  right: ${styles.PADDING_XS};
  top: ${styles.PADDING_XXS};
  text-decoration: none;
  padding: ${styles.PADDING_SM};
  color: ${({ theme }) => theme.DARK1};
  font-size: 1.6em;
  &:hover {
    cursor: pointer;
  }
`

const Addresses = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  flex-grow: 1;
  overflow-y: scroll;
  /* width: 100%; */
`

interface MultipleInputs {
  id: string
  text: string
  isValid: boolean
}

const TransferModel = () => {
  const { transferIds, screenWidth, transferContractAddress, wallet } = useApp()
  const dispatch = useDispatchApp()
  const [isVisible, setIsVisible] = useState(!isEmpty(transferIds))

  const handleClose = () => {
    dispatch({
      type: SET_TRANSFER,
      payload: {
        transferThings: [],
        contractAddress: null,
      },
    })
  }

  const [multipleInputs, setAddresses] = useState<MultipleInputs[]>([])

  useEffect(() => {
    setIsVisible(!isEmpty(transferIds))

    if (isEmpty(transferIds)) {
      setAddresses([])
      return
    }

    const multi = transferIds.map((id: any) => ({
      id,
      text: '',
      isValid: false,
    }))
    setAddresses(multi)
  }, [transferIds])

  const setValid = ({ id, text, isValid }: MultipleInputs) => {
    const newInputes = multipleInputs.map((input) => {
      if (id === input.id) {
        return { id, text, isValid }
      }
      return input
    })

    setAddresses(newInputes)
  }

  const handleOnTranfer = async () => {
    if (!wallet.isConnected() || !transferContractAddress) {
      dispatch({
        type: SET_ERROR,
        payload: 'Lost Connection, try signing out and back in again',
      })
      return
    }

    const validAccounts = multipleInputs.filter((input) => input.isValid)

    const tokenIds: [string, string][] = validAccounts.map(({ text, id }) => [
      id,
      text,
    ])

    wallet.transfer(tokenIds, transferContractAddress)
  }

  const styleProps = useSpring({
    transform: isVisible
      ? `translate(0px, 0px)`
      : `translate(${-screenWidth}px, 0px)`,
    opacity: isVisible ? 1 : 0,
  })

  return (
    <Container style={styleProps}>
      <FormContainer>
        <CloseButton onClick={handleClose}>
          <Close size={40} />
        </CloseButton>
        <FormContent autoComplete="off">
          <Header1>Transfer Tokens</Header1>

          <Subheader1>Airdrop to multiple addresses</Subheader1>
          <Addresses>
            {multipleInputs.slice(0, 8).map(({ text, id }: any) => (
              <TextCheck
                key={id}
                label="0x..."
                value={text}
                tokenId={id}
                onValid={setValid}
              />
            ))}
          </Addresses>
        </FormContent>

        <MintButtons>
          <WideButton
            background={styles.DARK3}
            color={styles.DARK1}
            onClick={handleOnTranfer}
          >
            Transfer
          </WideButton>
        </MintButtons>
      </FormContainer>
    </Container>
  )
}

export default TransferModel
