import styled from 'styled-components'
import * as styles from '../styles'
import { CheckCircle } from '@styled-icons/feather/CheckCircle'
import { XCircle } from '@styled-icons/feather/XCircle'
import { Star } from '@styled-icons/feather/Star'
import { Ticket } from '@styled-icons/icomoon/Ticket'
import { Circle } from '@styled-icons/feather/Circle'
import { Close } from '@styled-icons/evil/Close'
import { MarkunreadMailbox } from '@styled-icons/material-sharp/MarkunreadMailbox'
import { CloseO } from '@styled-icons/evil/CloseO'
import { Search } from '@styled-icons/feather/Search'
import { Face } from '@styled-icons/boxicons-solid/Face'
import { File } from '@styled-icons/feather/File'
import { Twitter } from '@styled-icons/icomoon/Twitter'
import { Upvote } from '@styled-icons/boxicons-regular/Upvote'
import { Lock2 } from '@styled-icons/remix-fill/Lock2'
import { Unlock } from '@styled-icons/evil/Unlock'
import { SkullCrossbones } from '@styled-icons/fa-solid/SkullCrossbones'
import { Home } from '@styled-icons/octicons/Home'
import { Store } from '@styled-icons/material/Store'
import { PlusCircle } from '@styled-icons/feather/PlusCircle'
import { Mountains } from '@styled-icons/foundation/Mountains'
import { Edit } from '@styled-icons/feather/Edit'

import { PersonBooth } from '@styled-icons/fa-solid/PersonBooth'
export const iconSize = 40
export const smallIconSize = '25px'

export const HomeIcon = styled(Home)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const PersonBoothIcon = styled(PersonBooth)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const SkullIcon = styled(SkullCrossbones)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const UnLockIcon = styled(Unlock)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const LockIcon = styled(Lock2)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const FaceIcon = styled(Face)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const TwitterIcon = styled(Twitter)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
  padding: 0;
`

export const UpvoteIcon = styled(Upvote)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const FileIcon = styled(File)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const CloseX = styled(Close)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
  width: ${iconSize};
  height: ${iconSize};
`

export const Pepper = styled(Ticket)`
  color: ${({ theme }) => theme.OFF_WHITE};
  align-self: center;
`

export const WinStar = styled(Star)`
  color: ${({ color }: any) => color || styles.OFF_WHITE};
  align-self: center;
`

export const Mountain = styled(Mountains)`
  color: ${({ color }: any) => color || styles.DARK};
  align-self: center;
`

export const Passed = styled(CheckCircle)`
  color: ${({ color }: any) => color || styles.OFF_WHITE};
  text-align: center;
  align-self: center;
`

export const Pending = styled(Circle)`
  color: ${({ color }: any) => color || styles.OFF_WHITE};
  align-self: center;
`

export const Blocked = styled(XCircle)`
  color: ${({ theme }) => theme.RED_ERROR};
  align-self: center;
`

export const SearchIcon = styled(Search)`
  color: ${({ color }: any) => color || styles.DARK2};
  align-self: center;
  /* margin: ${styles.PADDING_SM}; */
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: ${smallIconSize};
    height: ${smallIconSize};
  }
`

export const Nope = styled(XCircle)`
  color: ${({ color }: any) => color || styles.ACCENT};
  align-self: center;
  margin: ${styles.PADDING_SM};
`

export const Yup = styled(CheckCircle)`
  color: ${({ color }: any) => color || styles.GREEN};
  align-self: center;
  margin: ${styles.PADDING_SM};
`

export const Almost = styled(MarkunreadMailbox)`
  color: ${({ theme }) => theme.ACCENT};
  align-self: center;
  margin: ${styles.PADDING_SM};
`

export const StoreIcon = styled(Store)`
  color: ${({ color }: any) => color || styles.OFF_WHITE};
  align-self: center;
  margin: ${styles.PADDING_SM};
`

export const Plus = styled(PlusCircle)`
  color: ${({ color }: any) => color || styles.OFF_WHITE};
  align-self: center;
  margin: ${styles.PADDING_SM};
`

export const EditBase = styled(Edit)`
  color: ${({ color }: any) => color || styles.OFF_WHITE};
  align-self: center;
  margin: ${styles.PADDING_SM};
`
