export const HOME: '/' = '/'
export const USAGE: '/usage' = '/usage'
export const AUTH: '/auth' = '/auth'
export const HEALTH: '/health' = '/health'

export const PRIVACY: '/privacy' = '/privacy'
export const FEED: '/feed' = '/feed'
export const EARNED: '/earned' = '/earned'
export const DEPLOY: '/deploy' = '/deploy'
export const PROFILE_IMAGE: '/profile/bob.jpg' = '/profile/bob.jpg'
export const CONTRACT: '/contract' = '/contract'
export const STORE: '/store/[contract]' = '/store/[contract]'
export const MINT: '/store/[contract]/mint' = '/store/[contract]/mint'
export const DEVELOPERS: '/developers' = '/developers'
export const DEVELOPER: '/developer' = '/developer'
export const CAREERS = '/careers'
export const ROYALTIES = '/royalties'
export const DATA = '/data'
export const SETTINGS = '/settings'
export const DASHBOARD: '/dashboard/[user]/[page]' = '/dashboard/[user]/[page]'
export const THREEXR = 'https://3xr.space/store/'
export const TESTNET_EXPLORER_URI =
  'https://explorer-testnet.hasura.app/api/rest'
export const MAINNET_EXPLORER_URI = 'https://explorer.hasura.app/api/rest'

export const MINTER: '/minter/[contract]/[account]' =
  '/minter/[contract]/[account]'

export const MARKET: '/market' = '/market'
export const COMMUNITY: '/community' = '/community'
export const GRAPH_PORTAL: 'https://graphiql-online.com/' =
  'https://graphiql-online.com/'
export const PEZ_DISPENSER: '/pez-dispenser/[contract]/[metaId]' =
  '/pez-dispenser/[contract]/[metaId]'

export const SELL_THING: '/sell/[contract]/[metaId]' =
  '/sell/[contract]/[metaId]'

export const MY_THING: '/my-thing/:contractAddress/:metaId/:thingId' =
  '/my-thing/:contractAddress/:metaId/:thingId'

export const MY_MARKET: '/my-market/[contract]' = '/my-market/[contract]'

export const EMBED_MARKET: '/embed-market/:contractAddress/:minter' =
  '/embed-market/:contractAddress/:minter'

export const ALL_MARKET: '/all-market/:contractAddress/:minter' =
  '/all-market/:contractAddress/:minter'
export const ALL_MARKETS: '/markets/[search]/[page]/[category]' =
  '/markets/[search]/[page]/[category]'
export const ALL_TOKENS: '/markets/tokens' = '/markets/tokens'

export const REDEEMER: '/redeemer/[contract]' = '/redeemer/[contract]'

export const REDEEM: '/redeem/:contract' = '/redeem/:contract'

export const CONTRACT_SETTINGS: '/settings/contract/[contract]' =
  '/settings/contract/[contract]'
export const PROFILE: '/profile/[user]' = '/profile/[user]'

export const DOCS: 'https://docs.mintbase.io' = 'https://docs.mintbase.io'
export const RINKEBY_URL: 'https://rinkeby.mintbase.io' =
  'https://rinkeby.mintbase.io'

export const DOCS_DEVELOPERS: 'https://docs.mintbase.io/dev/getting-started' =
  'https://docs.mintbase.io/dev/getting-started'

export const PRIVACY_URL: 'https://docs.mintbase.io/privacy-policy-and-terms-of-service' =
  'https://docs.mintbase.io/privacy-policy-and-terms-of-service'

export const DAPP_QUERY: ' https://dashboard.dappquery.com/d/0VwYLwjWk/mintbase?orgId=1&from=now-5M&to=now&kiosk&presentation_mode' =
  ' https://dashboard.dappquery.com/d/0VwYLwjWk/mintbase?orgId=1&from=now-5M&to=now&kiosk&presentation_mode'

export const NEAR_EXPLORER_TESTNET: 'https://explorer.testnet.near.org' =
  'https://explorer.testnet.near.org'

export const NEAR_EXPLORER_MAINNET: 'https://explorer.near.org' =
  'https://explorer.near.org'

export const NEAR_TESTNET_RPC: 'https://rpc.testnet.near.org' =
  'https://rpc.testnet.near.org'

export const NEAR_MAINNET_RPC: 'https://rpc.mainnet.near.org' =
  'https://rpc.mainnet.near.org'

export const BASE_ARWEAVE_URI: 'https://arweave.net/' = 'https://arweave.net/'

export const BASE_ARWEAVE_GATEWAY: 'https://gateway.amplify.host' =
  'https://gateway.amplify.host'

export const COLD_ARWEAVE_GATEWAY: 'https://coldcdn.com/api/cdn/bronil/' =
  'https://coldcdn.com/api/cdn/bronil/'

export const GRAPH_TESTNET: 'https://mintbase-testnet.hasura.app/v1/graphql' =
  'https://mintbase-testnet.hasura.app/v1/graphql'

export const GRAPH_MAINNET: 'https://mintbase-mainnet.hasura.app/v1/graphql' =
  'https://mintbase-mainnet.hasura.app/v1/graphql'

export const ARWEAVE_BLOCK_EXPLORER: 'https://viewblock.io/arweave/tx' =
  'https://viewblock.io/arweave/tx'

export const DOC_STORE = 'https://docs.mintbase.io/creating/store'

export const DEV_DOCS = 'https://docs.mintbase.io/dev/getting-started'

export const DOCS_ADD_MINTERS =
  'https://docs.mintbase.io/creating/store/add-minters'
// export const GRAPH_URI:'https://mintdev.hasura.app/v1/graphql' = 'https://mintdev.hasura.app/v1/graphql'

export const DEFAULT_HEADER_IMAGE: '/header/mintbase-header.jpg' =
  '/header/mintbase-header.jpg'

export const BASE_CONTRACT: 'mintbase13.testnet' = 'mintbase13.testnet'

export const nearContractUri = (explorer: string, contract: string) =>
  `${explorer}/accounts/${contract}`

export const nearTransactionUri = (explorer: string, transactionHash: string) =>
  `${explorer}/transactions/${transactionHash}`

export const marketAccount = `0.${BASE_CONTRACT}`

export const getHeader = (dir: string) =>
  `https://firebasestorage.googleapis.com/v0/b/omni-base-1.appspot.com/o/store%2F${dir.toString()}-header?alt=media`

export const getFirestoreImage = (dir: string, name: string) =>
  `https://firebasestorage.googleapis.com/v0/b/omni-base-1.appspot.com/o/${dir}%2F${name}?alt=media`

export const getStoreId = (name: string) => `${name}.${BASE_CONTRACT}`

export const CREATE_BASE: 'https://createbase.community/' =
  'https://createbase.community/'

export const EMAIL_CAREERS = 'mailto:careers@mintbase.io'
export const NPM = 'https://www.npmjs.com/package/mintbase'
export const DISCORD = 'https://discord.gg/89cSHH7'
export const TYPESCRIPT = 'https://mintbase.github.io/mintbase-js/index.html'
export const GITHUB = 'https://github.com/mintbase'
export const TELEGRAM_DEV = 'https://t.me/mintdev'
export const DISCROD_URL: 'https://discord.gg/89cSHH7' =
  'https://discord.gg/89cSHH7'
export const TWITTER = 'https://twitter.com/mintbase'
export const TELEGRAM_CREATEBASE = 'https://t.me/creatorsNEAR'
export const TELEGRAM_URL: 'https://t.me/mintbase' = 'https://t.me/mintbase'
export const WHY_NEAR =
  'https://medium.com/mintbase/scaling-mintbase-with-near-503375d92702'
export const WHY_ARWEAVE =
  'https://medium.com/mintbase/mintbase-arweave-9459f3889c79'
export const INSTAGRAM = 'https://www.instagram.com/mintbase_'
export const NEP171 =
  'https://github.com/near/NEPs/tree/master/specs/Standards/NonFungibleToken'

export const YOUTUBE =
  'https://www.youtube.com/channel/UCLFeW-AZNC_VfPvxiA3k8Vg/videos'
export const MEDIUM = 'https://medium.com/mintbase'

export const LINKEDIN = 'https://www.linkedin.com/company/mintbase/'
export const CREATE_MINTBASE_APP =
  'https://github.com/Mintbase/create-mintbase-app'

export const CURRENT_MARKET_ADDRESS = 'market.mintspace2.testnet'
export const FACEBOOK = 'https://www.facebook.com/mintbase'
export const FORUM = 'https://gov.near.org/c/creatives/createbase'
export const REDDIT = 'http://reddit.com/r/mintbase'
export const PRESS_KIT =
  'https://firebasestorage.googleapis.com/v0/b/thing-1d2be.appspot.com/o/assets%2Fmintbase_logos.zip?alt=media&token=67eab87f-ddd4-4fc4-8590-57127ccc0749'

export const DAO = 'https://www.sputnik.fund/#/dao/createbase.sputnikdao.near'
