import React, {
  Dispatch,
  SetStateAction,
  useReducer,
  useContext,
  useMemo,
} from 'react'
import BN from 'bn.js'
import * as dark from 'styles/dark'
import * as light from 'styles/light'
import {
  ListRelation,
  NetworkConnection,
  Store,
  Thing,
  ThingRelation,
} from 'types/base'
import * as styles from 'styles'
import { User } from 'types/base'
import { ThemeProvider } from 'styled-components'
import { Contract, WalletConnection } from 'near-api-js'
import {
  Wallet as MintbaseWallet,
  Network as MintbaseNetwork,
  Chain as MintbaseChain,
} from 'mintbase'

const testingMinter = false

const defaultMinter = {
  isOpen: false,
  selectedPropertyIndex: 0,
  editable: false,
}
const testMinter = {
  isOpen: true,
  selectedPropertyIndex: 2,
  editable: true,
}

export enum Networks {
  ethereum = 'ethereum',
  mainnet = 'mainnet',
  testnet = 'near',
}

interface Account {
  lastLogin: string
  createdAt: string
  netowrk: Networks
  userId: string
  account: string
}

export enum Wallet {
  Metamask = 1,
  Fortmatic,
  WalletConnect,
  WalletLink,
  Near,
}

export enum EthConnection {
  NoConnection,
  NeedsMobile,
  NeedsBrowser,
  NeedsWeb3,
  NeedsSign,
  Connected,
}

export enum TraitType {
  startDate = 'Start Date',
  endDate = 'End Date',
  placeId = 'place_id',
  website = 'website',
  zoom = 'zoom',
}

export enum DisplayType {
  boostNumber = 'boost_number',
  boostPercentage = 'boost_percentage',
  number = 'number',
  date = 'date',
  location = 'location',
  website = 'website',
  zoom = 'zoom',
  placeId = 'place_id',
  rarity = 'rarity',
  youtubeUrl = 'youtube_url',
  latitude = 'latitude',
  longitude = 'longitude',
}

export interface Extra {
  trait_type: string
  display_type?: DisplayType
  value: string | number
}

export enum Visibilities {
  nsfw = 'nsfw',
  safe = 'safe',
}

export enum Chain {
  near = 'near',
  ethereum = 'ethereum',
  nearTestnet = 'near-testnet',
  rinkeby = 'rinkeby',
}

export enum Mintstep {
  Royalties = 'royalties',
  Splits = 'splits',
  Calendar = 'calendar',
  Media = 'media',
  Pdf = 'pdf',
  Location = 'location',
  Website = 'website',
  Custom = 'custom',
}

export enum Field {
  Id = 'id',
  Title = 'title',
  Category = 'category',
  Description = 'description',
  Media = 'media',
  Media_hash = 'media_hash',
  Tags = 'tags',
  Image_preview = 'imagePreview',
  Copies = 'copies',
  Extra = 'extra',
  External_url = 'external_url',
  Background_color = 'background_color',
  Animation_url = 'animation_url',
  Animation_hash = 'animation_hash',
  Youtube_url = 'youtube_url',
  UpdatedAt = 'updated_at',
  Document = 'document',
  Document_hash = 'document_hash',
  Lock = 'lock',
  Visibility = 'visibility',
  Chain = 'chain',
  Store = 'store',
  Royalty = 'royalty',
  Royalty_perc = 'royalty_perc',
  SplitRevenue = 'split_revenue',
  Calendar = 'calendar',
  Website = 'website',
  Location = 'location',
  Custom = 'custom',
}

export interface Metadata {
  [Field.Id]?: string
  [Field.Title]: string
  [Field.Category]: string | null
  [Field.Description]: string | null
  [Field.Media]: string
  [Field.Media_hash]: string | null
  [Field.Tags]: string[]
  [Field.Image_preview]: string | null
  [Field.Copies]: number
  [Field.Extra]: Extra[]
  [Field.External_url]: string | null
  [Field.Background_color]: string | null
  [Field.Animation_url]: string | null
  [Field.Animation_hash]: string | null
  [Field.Youtube_url]: string | null
  [Field.Document]: string | null
  [Field.Document_hash]: string[] | null
  [Field.Lock]: string[]
  [Field.Visibility]: Visibilities
  [Field.Chain]: Chain
  [Field.Store]?: string | null
  [Field.Royalty]: { [key: string]: number } | null
  [Field.Royalty_perc]: number | null
  [Field.Calendar]: Extra[] | null
  [Field.Website]: Extra[] | null
  [Field.Location]: Extra[] | null
  [Field.Custom]: Extra[] | null
}

export type MintMetadata = Metadata & {
  animation_size?: number
  media_size?: number
  animation_type?: string
  media_type?: string
}

export const defaultMint = {
  [Field.Title]: '',
  [Field.Category]: 'art',
  [Field.Description]: '',
  [Field.Media]: '',
  [Field.Media_hash]: null,
  [Field.Tags]: [],
  [Field.Image_preview]: null,
  [Field.Copies]: 1,
  [Field.Extra]: [],
  [Field.External_url]: null,
  [Field.Background_color]: null,
  [Field.Animation_url]: null,
  [Field.Animation_hash]: null,
  [Field.Youtube_url]: null,
  [Field.Lock]: null,
  [Field.Document]: null,
  [Field.Document_hash]: null,
  [Field.Visibility]: Visibilities.safe,
  [Field.Chain]: Chain.nearTestnet,
  [Field.Royalty_perc]: 0.1,
  [Field.Royalty]: null,
  [Field.SplitRevenue]: null,
  [Field.Calendar]: null,
  [Field.Website]: null,
  [Field.Location]: null,
  [Field.Custom]: null,
}

interface Minter {
  isOpen: boolean
  selectedPropertyIndex: number
  editable: boolean
}

interface WalletDetails {
  balance: string
  allowance: string
}

export interface PurchaseItem {
  tokenId: string
  price: string
  withCard: boolean
  requiresEmail: boolean
  fiatPrice: string
  ethPrice: string
  metaId: string
}

interface BoxSocial {
  username: string
}

interface verifiedAccounts {
  github: BoxSocial
  twitter: BoxSocial
}

interface Profile {
  description: string
  empji: string
  image: string
  name: string
  website: string
  verifiedAccounts: verifiedAccounts
}

interface Meter {
  limit: number
  usedPercent: number
  transactions: string[]
  used: number
  userAddress: string | null
}

export const defaultUser = {
  id: '',
  name: '',
  username: '',
  displayName: '',
  account: null,
  description: '',
  siteUrl: '',
  twitterName: '',
  communityUrl: '',
  image: '',
  headerImage: '',
  profileImage: '',
  email: '',
  hasProfile: false,
  verifiedAccounts: {
    twitter: null,
  },
}

// export interface MarketContract {}

export interface MContract {
  get_token_owner_id: () => Promise<string>
  get_token: () => Promise<string>
  get_token_token_id: () => Promise<string>
  make_offer: (
    {
      token_key,
      price,
      timeout,
    }: { token_key: string; price: string; timeout: any },
    max?: BN,
    attached?: string
  ) => Promise<string>
}

export interface MarketContract extends MContract, Contract {}

export interface NFTContract {
  get_name: () => Promise<string>
  grant_access: ({ escrow_account_id }: { escrow_account_id: string }) => void
  revoke_access: ({ escrow_account_id }: { escrow_account_id: string }) => void
  token_uri: ({ token_id }: { token_id: string }) => any
  destroy: () => void
  get_marketplace: () => Promise<string>
  set_marketplace: ({ market_address }: { market_address: string }) => void
  list_tokens: (
    {
      contract_address,
      token_ids,
      autotransfer,
      asking_price,
      split_owners,
    }: {
      contract_address: string | null
      token_ids: number[]
      autotransfer: boolean
      asking_price: string
      split_owners: { [key: string]: number } | null
    },
    max: BN,
    gas: string
  ) => void
  ext_marketplace_push_new_token: ({
    token_id,
    autotransfer,
    asking_price,
    royalties,
    split_owners,
  }: {
    token_id: string
    autotransfer: boolean
    asking_price: number
    royalties: string[] | null
    split_owners: string[] | null
  }) => void
  new: ({
    owner_id,
    name,
    symbol,
    base_uri,
  }: {
    owner_id: string
    name: string
    symbol: string
    base_uri: string
  }) => any
  mint_tokens: (
    {
      owner_id,
      meta_id,
      num_to_mint,
      royalty_f,
    }: {
      owner_id: string
      meta_id: string
      num_to_mint: number
      royalty_f: number
      royalty: any | null
    },
    max: BN,
    zero: BN
  ) => void
  get_token_owner: ({ token_id }: { token_id: number }) => Promise<string>
  check_access: ({
    token_owner_account_id,
  }: {
    token_owner_account_id: string
  }) => Promise<string>
  transfer: ({
    new_owner_id,
    token_id,
  }: {
    new_owner_id: string
    token_id: string
  }) => void
  set_icon_base64: ({ base64 }: { base64: string }) => void
  set_base_uri: ({ base_uri }: { base_uri: string }) => void

  transfer_from: ({
    owner_id,
    new_owner_id,
    token_id,
  }: {
    owner_id: string
    new_owner_id: string
    token_id: string
  }) => void
  transfer_ownership: ({ account_id }: { account_id: string }) => boolean
  batch_burn: (
    { token_ids }: { token_ids: number[] },
    max: BN,
    zero: BN
  ) => boolean
  batch_transfer: (
    { token_ids }: { token_ids: [string, number][] },
    max: BN,
    zero: BN
  ) => void
  grant_minter: (
    { account_id }: { account_id: string },
    max: BN,
    zero: BN
  ) => void
  renounce_minter: (
    { account_id }: { account_id: string },
    max: BN,
    zero: BN
  ) => void
}

export interface StoreContract extends NFTContract, Contract {}

export interface AppContextInterface {
  navOpen: boolean
  setNavOpen: Dispatch<SetStateAction<boolean>>
  setStore: Dispatch<SetStateAction<null>>
  provider: any | null
  web3: any | null
  ethereum: any | null
  wallet: MintbaseWallet | null
  connectedProvider: any | null
  signer: any | null
  account: string | null
  errorMessage: string | null
  errorUrl: string | null
  message: string | null
  signedContract: StoreContract | null
  network: NetworkConnection | null
  contractName: string | null
  contractSymbol: string | null
  contractAddress: string | null
  transferIds: string[]
  needsWallet: boolean | string
  isMobile: boolean
  createContract: boolean
  stores: Store[]
  loaderMessage: string | null
  store: Store | null
  things: ThingRelation[]
  ethPrice: number
  editThing: Thing | null
  transferContract: any
  ethStatus: EthConnection
  transferContractAddress: string | null
  showTransactionStarted: boolean
  isAppMode: boolean
  mint: MintMetadata
  nearConnection: WalletConnection | null
  minter: Minter
  gasPrice: string | null
  connectContract: string | null
  storage: any
  newStoreOpen: boolean
  windowLoaded: boolean
  providerLoaded: boolean
  host: string
  isLoggedOut: boolean
  storesLoaded: boolean
  redeemerProvider: any | null
  redeemerWeb3: any | null
  isRedeemer: boolean
  redeemerAccount: string
  storeLoaded: boolean
  email: string | null
  emailVerified: boolean
  payByCard: boolean
  purchaseItem: PurchaseItem | null
  Box: any | null
  stripeAccount: string | null
  hasFiatThing: boolean
  stripeCanCollectPayment: boolean
  withCard: boolean
  purchedThingId: string | null
  isEmbeded: boolean
  embedPez: string | null
  viewableAccount: string | null
  acceptedGDPR: boolean
  profile: Profile | null
  foundStores: any | null
  customProperties: Extra[]
  meter: Meter
  screenWidth: number | null
  screenHeight: number | null
  storeCreationPending: boolean
  blockchain: Networks
  storeContract: StoreContract | null
  userImage: string
  userImageSmall: string
  nearPrice: number | null
  colors: typeof dark | typeof light
  isCroppingImage: boolean
  croppingImage: string | null
  cropCallback: (image: string) => void
  cropAspectRatio: number
  user: User | null
  isDark: boolean
  accounts: Account[]
  isMod: boolean
  isStoreDeployer: boolean
  walletDetails: WalletDetails
  marketMints: ListRelation[]
  currentTotalRoyalties: number
  currentTotalRevenue: number
}

const defaultMeter: Meter = {
  limit: styles.FREE_LIMIT,
  usedPercent: 0,
  transactions: [],
  used: 0,
  userAddress: null,
}

const defaultWalletDetails: WalletDetails = {
  balance: '0',
  allowance: '0',
}

export const defaultState = {
  navOpen: false,
  setNavOpen: () => {},
  setStore: () => {},
  wallet: null,
  connectedProvider: null,
  signer: null,
  account: null,
  errorMessage: null,
  errorUrl: null,
  message: null,
  signedContract: null,
  blockchain: Networks.testnet,
  network: null,
  accounts: [],
  contractName: null,
  contractSymbol: null,
  contractAddress: null,
  nearConnection: null,
  transferIds: [],
  needsWallet: false,
  isMobile: false,
  createContract: false,
  loaderMessage: null,
  showTransactionStarted: false,
  stores: [],
  store: null,
  Thing: [],
  ethPrice: 0,
  editThing: null,
  things: [],
  transferContract: null,
  ethStatus: EthConnection.NoConnection,
  transferContractAddress: null,
  isAppMode: false,
  embedPez: null,
  mint: defaultMint,
  gasPrice: null,
  minter: testingMinter ? testMinter : defaultMinter,
  connectContract: null,
  storage: null,
  newStoreOpen: false,
  windowLoaded: false,
  host: '',
  provider: null,
  redeemerProvider: null,
  web3: null,
  redeemerWeb3: null,
  ethereum: null,
  isLoggedOut: false,
  storeLoaded: false,
  isRedeemer: false,
  redeemerAccount: '',
  email: null,
  emailVerified: false,
  payByCard: false,
  purchaseItem: null,
  providerLoaded: false,
  Box: null,
  stripeAccount: null,
  hasFiatThing: false,
  stripeCanCollectPayment: false,
  withCard: false,
  purchedThingId: null,
  isEmbeded: false,
  viewableAccount: null,
  acceptedGDPR: false,
  profile: null,
  foundStores: null,
  customProperties: [],
  meter: defaultMeter,
  screenWidth: 3000,
  screenHeight: 3000,
  storeCreationPending: false,
  storeContract: null,
  userImageSmall: '/human/bob.jpg',
  userImage: '/human/bob.jpg',
  nearPrice: 0,
  isCroppingImage: false,
  croppingImage: '',
  cropCallback: null,
  cropAspectRatio: 1 / 1,
  colors: dark,
  isDark: true,
  user: null,
  isMod: false,
  isStoreDeployer: false,
  walletDetails: defaultWalletDetails,
  marketMints: [],
  currentTotalRoyalties: 0,
  currentTotalRevenue: 0,
  storesLoaded: false,
}

export const UPDATE_MINT: 'UPDATE_MINT' = 'UPDATE_MINT'
export const RESET_MINT: 'RESET_MINT' = 'RESET_MINT'
export const SET_ETH_PRICE: 'SET_ETH_PRICE' = 'SET_ETH_PRICE'
export const SET_EMBED_THING: 'SET_EMBED_THING' = 'SET_EMBED_THING'
export const SET_APP_MODE: 'SET_APP_MODE' = 'SET_APP_MODE'
export const SET_CONTRACT: 'SET_CONTRACT' = 'SET_CONTRACT'
export const SET_ACCOUNT: 'SET_ACCOUNT' = 'SET_ACCOUNT'
export const OPEN_DOOR: 'OPEN_DOOR' = 'OPEN_DOOR'
export const CLOSE_DOOR: 'CLOSE_DOOR' = 'CLOSE_DOOR'
export const SET_WALLET_CONNECT: 'SET_WALLET_CONNECT' = 'SET_WALLET_CONNECT'
export const SET_PROVIDER: 'SET_PROVIDER' = 'SET_PROVIDER'
export const SET_ERROR: 'SET_ERROR' = 'SET_ERROR'
export const SET_MESSAGE: 'SET_MESSAGE' = 'SET_MESSAGE'
export const SET_LOADER: 'SET_LOADER' = 'SET_LOADER'
export const SET_TRANSFER: 'SET_TRANSFER' = 'SET_TRANSFER'
export const LOGOUT: 'LOGOUT' = 'LOGOUT'
export const SET_IS_MOBILE: 'SET_IS_MOBILE' = 'SET_IS_MOBILE'
export const SET_DARK_MODE: 'SET_DARK_MODE' = 'SET_DARK_MODE'
export const SET_LIGHT_MODE: 'SET_LIGHT_MODE' = 'SET_LIGHT_MODE'
export const SET_CONNECTION_STATE: 'SET_CONNECTION_STATE' =
  'SET_CONNECTION_STATE'
export const UPDATE_MINTER: 'UPDATE_MINTER' = 'UPDATE_MINTER'
export const SET_GAS_PRICE: 'SET_GAS_PRICE' = 'SET_GAS_PRICE'
export const SET_MAIN_PROVIDER: 'SET_MAIN_PROVIDER' = 'SET_MAIN_PROVIDER'
export const INIT_BLOCKCHAIN: 'INIT_BLOCKCHAIN' = 'INIT_BLOCKCHAIN'
export const TOGGLE_NEW_STORE_OPEN: 'TOGGLE_NEW_STORE_OPEN' =
  'TOGGLE_NEW_STORE_OPEN'

export const SET_USER = 'SET_USER'
export const SET_WALLET: 'SET_WALLET' = 'SET_WALLET'
export const SET_WALLET_MINTBASEJS: 'SET_WALLET_MINTBASEJS' =
  'SET_WALLET_MINTBASEJS'
export const TOGGLE_LOGOUT: 'TOGGLE_LOGOUT' = 'TOGGLE_LOGOUT'
export const SET_STORES: 'SET_STORES' = 'SET_STORES'
export const DISCONNECT: 'DISCONNECT' = 'DISCONNECT'
export const SET_STORE: 'SET_STORE' = 'SET_STORE'
export const SET_PURCHASE_ITEM: 'SET_PURCHASE_ITEM' = 'SET_PURCHASE_ITEM'
export const SET_PAY_BY_CARD: 'SET_PAY_BY_CARD' = 'SET_PAY_BY_CARD'
export const CAN_PAY_BY_CARD: 'CAN_PAY_BY_CARD' = 'CAN_PAY_BY_CARD'
export const TOGGLE_DOOR: 'TOGGLE_DOOR' = 'TOGGLE_DOOR'
export const ACCEPT_GDPR: 'ACCEPT_GDPR' = 'ACCEPT_GDPR'
export const SET_MARKET_MINTS: 'SET_MARKET_MINTS' = 'SET_MARKET_MINTS'
export const START_CROP_IMAGE: 'START_CROP_IMAGE' = 'START_CROP_IMAGE'
export const CANCEL_CROP_IMAGE: 'CANCEL_CROP_IMAGE' = 'CANCEL_CROP_IMAGE'
export const FINISH_CROP_IMAGE: 'FINISH_CROP_IMAGE' = 'FINISH_CROP_IMAGE'

export const SET_TOKEN_MOD: 'SET_TOKEN_MOD' = 'SET_TOKEN_MOD'
export const SET_TOKEN_STORE_DEPLOYER: 'SET_TOKEN_STORE_DEPLOYER' =
  'SET_TOKEN_STORE_DEPLOYER'

export const appReducer = (
  state: AppContextInterface = defaultState,
  action: any
) => {
  const { payload, type } = action

  // const payloadString = payload instanceof Object ? JSON.stringify(payload) : '';

  switch (action.type) {
    case SET_STORES:
      return {
        ...state,
        stores: action.payload,
        storesLoaded: true,
      }
    case SET_PURCHASE_ITEM:
      return {
        ...state,
        purchaseItem: action.payload.purchaseItem,
        payByCard: action.payload.payByCard,
      }
    case SET_WALLET_CONNECT:
      return {
        ...state,
        needsWallet: action.payload.needsWallet,
        connectContract: action.payload.contract,

        payByCard: action.payload.payByCard ? action.payload.payByCard : false,
        providerLoaded: action.payload.providerLoaded
          ? action.payload.providerLoaded
          : false,
      }

    case SET_PAY_BY_CARD:
      return {
        ...state,
        payByCard: action.payload,
        purchedThingId: null,
      }
    case SET_STORE:
      return {
        ...state,
        things: action.payload,
        storeLoaded: true,
      }

    case TOGGLE_LOGOUT:
      return {
        ...state,
        isLoggedOut: !state.isLoggedOut,
      }
    case SET_MARKET_MINTS:
      return {
        ...state,
        marketMints: action.payload,
      }
    case SET_LIGHT_MODE:
      return {
        ...state,
        colors: light,
        isDark: false,
      }
    case SET_DARK_MODE:
      return {
        ...state,
        colors: dark,
        isDark: true,
      }

    case TOGGLE_NEW_STORE_OPEN:
      return {
        ...state,
        newStoreOpen: !state.newStoreOpen,
      }
    case SET_WALLET:
      return {
        ...state,
        wallet: action.payload.wallet,
        isRedeemer:
          action.payload.isRedeemer && action.payload.isRedeemer !== 'undefined'
            ? action.payload.isRedeemer
            : false,
      }

    case SET_WALLET_MINTBASEJS:
      return {
        ...state,
        wallet: action.payload.wallet,
      }

    case RESET_MINT:
      return {
        ...state,
        mint: defaultMint,
        minter: defaultMinter,
      }
    case UPDATE_MINT:
      return {
        ...state,
        mint: {
          ...state.mint,
          ...action.payload,
        },
      }
    case UPDATE_MINTER:
      return {
        ...state,
        minter: {
          ...state.minter,
          ...action.payload,
        },
      }
    case TOGGLE_DOOR:
      return {
        ...state,
        navOpen: !state.navOpen,
      }
    case OPEN_DOOR:
      return {
        ...state,
        navOpen: true,
      }
    case CLOSE_DOOR:
      return {
        ...state,
        navOpen: false,
      }
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      }
    case ACCEPT_GDPR:
      localStorage.setItem('gdpr', 'true')
      return {
        ...state,
        acceptedGDPR: true,
      }
    case SET_ACCOUNT:
      localStorage.setItem('account', action.payload.account)
      return {
        ...state,
        account: action.payload.account,
        email: action.payload.email,
      }
    case SET_ETH_PRICE:
      return {
        ...state,
        ethPrice: action.payload.ethPrice,
        gasPrice: action.payload.gasPrice,
        nearPrice: action.payload.nearPrice,
      }
    case SET_CONTRACT:
      localStorage.setItem('contract', action.payload)
      // localStorage.setItem("nearContract", action.payload);
      return {
        ...state,
        contractAddress: action.payload,
      }
    case SET_APP_MODE:
      return {
        ...state,
        isAppMode: action.payload,
      }

    case SET_PROVIDER:
      return {
        ...state,
        ...action.payload,
      }
    case SET_MAIN_PROVIDER:
      localStorage.setItem('wallet', action.payload.wallet)
      return {
        ...state,
        providerLoaded: true,
        provider: action.payload.provider,
        signer: action.payload.signer,
        wallet: action.payload.wallet,
        web3: action.payload.web3,
        account: action.payload.account,
      }
    case SET_ERROR:
      if (!!action?.payload?.url) {
        return {
          ...state,
          errorMessage: action.payload.message,
          errorUrl: action.payload.url,
        }
      }

      return {
        ...state,
        errorMessage: action.payload,
      }
    case SET_CONNECTION_STATE:
      return {
        ...state,
        ethStatus: action.payload,
      }
    case SET_LOADER:
      return {
        ...state,
        loaderMessage: action.payload,
      }
    case SET_EMBED_THING:
      return {
        ...state,
        embedPez: action.payload,
      }
    case SET_TRANSFER:
      return {
        ...state,
        transferIds: action.payload.transferThings,
        transferContractAddress: action.payload.contractAddress,
      }

    case CAN_PAY_BY_CARD:
      return {
        ...state,
        ...action.payload,
      }

    case LOGOUT:
      return {
        ...defaultState,
        account: null,
        needsWallet: null,
        isLoggedOut: true,
      }

    case DISCONNECT:
      // localStorage.clear();
      localStorage.removeItem('wallet')

      return {
        ...state,
        provider: null,
        needsWallet: null,
        redeemerProvider: null,
        redeemerAccount: '',
        redeemerWeb3: null,
        wallet: null,
      }

    case START_CROP_IMAGE:
      return {
        ...state,
        isCroppingImage: true,
        croppingImage: action.payload.croppingImage,
        cropCallback: action.payload.cropCallback,
        cropAspectRatio: action.payload.cropAspectRatio,
      }
    case CANCEL_CROP_IMAGE:
      return {
        ...state,
        isCroppingImage: false,
        croppingImage: '',
        cropCallback: null,
        cropAspectRatio: 1 / 1,
      }
    case FINISH_CROP_IMAGE:
      return {
        ...state,
        isCroppingImage: false,
        croppingImage: '',
        cropCallback: null,
        cropAspectRatio: 1 / 1,
      }

    case SET_TOKEN_MOD:
      return {
        ...state,
        isMod: action.payload.isMod,
      }

    case SET_TOKEN_STORE_DEPLOYER:
      return {
        ...state,
        isStoreDeployer: action.payload.isStoreDeployer,
      }

    case SET_MESSAGE: {
      return {
        ...state,
        message: action.payload,
      }
    }
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    default:
      return state
  }
}

interface DispatchPayload {
  type: string
  payload: any
}

interface Context {
  state: AppContextInterface
  dispatch: Dispatch<DispatchPayload>
  Provider: any
  Consumer: any
}

// @ts-ignore
export const AppContextProvider = React.createContext()

// @ts-ignore
const AppDispatchContext = React.createContext()

interface AppProviderProps {
  children: any
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, { ...defaultState })
  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <ThemeProvider theme={state.colors}>
      <AppDispatchContext.Provider value={contextValue.dispatch}>
        <AppContextProvider.Provider value={contextValue.state}>
          {children}
        </AppContextProvider.Provider>
      </AppDispatchContext.Provider>
    </ThemeProvider>
  )
}

// @ts-ignore
export const useApp = () => useContext<AppContextInterface>(AppContextProvider)
export const useDispatchApp = () => useContext<any>(AppDispatchContext)
