import React from 'react'
import styled from 'styled-components'
import * as styles from '../styles'
import { useRouter } from 'next/router'
import SocialButtons from './SocialButtons'
import * as paths from '../content/paths'
import { Copy, SmallerHeader } from './Typography'
import { useApp } from 'components/AppContext'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1 0 auto;
  padding: ${styles.PADDING_MD};
  background-color: ${({ theme }) => theme.DARK4};
  justify-content: space-between;
  padding-top: ${styles.PADDING_LG};
  padding-bottom: ${styles.PADDING_LG};

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    /* font-size: 1.1em; */
    /* padding: ${styles.PADDING_SM}; */
    flex-direction: column;
    padding: ${styles.PADDING_XXS};
  }
`

const FooterContent = styled.div`
  display: flex;
  /* flex-grow: 1; */
  padding: ${styles.PADDING_SM};
  flex-direction: column;
  /* justify-content: center; */
`

const RightButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
  }
`

const ButtonColumn = styled.div`
  display: flex;
  /* flex-grow: 1; */
  flex-direction: column;
  margin-left: ${styles.PADDING_XXS};
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: ${styles.PADDING_MD};
  flex-direction: column;

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    text-align: center;
  }
`

const FooterLink = styled.a`
  font-size: 0.9em;
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  color: ${({ color, theme }) => theme.OFF_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.DARK3};
  text-align: center;
  padding: ${styles.PADDING_SM};
  margin: ${styles.PADDING_XXS};
  /* flex-grow: 1; */
  text-decoration: none;
  font-weight: 300;
  text-align: left;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1rem;
  }
`

const FooterInternalLink = styled.a`
  font-size: 0.9em;
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  color: ${({ theme }) => theme.OFF_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.DARK3};
  text-align: center;
  padding: ${styles.PADDING_SM};
  margin: ${styles.PADDING_XXS};
  /* flex-grow: 1; */
  text-decoration: none;
  font-weight: 300;
  text-align: left;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1rem;
  }
`

interface LinkItem {
  title: string
  path: string
  isExternal: boolean
}
interface Item {
  name: string
  links: LinkItem[]
}

const Footer = () => {
  const router = useRouter()
  const { colors } = useApp()
  const { t } = useTranslation('common')

  const items = [
    {
      name: t`developers`,
      links: [
        {
          title: t`docs`,
          path: paths.DEV_DOCS,
          isExternal: true,
        },
        {
          title: t`overview`,
          path: paths.DEVELOPERS,
          isExternal: false,
        },
        {
          title: t`mintbasejs`,
          path: paths.NPM,
          isExternal: true,
        },
        {
          title: t`dev-support`,
          path: paths.TELEGRAM_DEV,
          isExternal: true,
        },
      ],
    },
    {
      name: t`company`,
      links: [
        {
          title: t`careers`,
          path: paths.CAREERS,
          isExternal: false,
        },
        {
          title: t`blog`,
          path: paths.MEDIUM,
          isExternal: true,
        },
        {
          title: t`privacy`,
          path: paths.PRIVACY_URL,
          isExternal: true,
        },
        {
          title: t`press-kit`,
          path: paths.PRESS_KIT,
          isExternal: true,
        },
      ],
    },
    {
      name: t`support`,
      links: [
        {
          title: t`guides`,
          path: paths.DOCS,
          isExternal: true,
        },
        {
          title: t`telegram`,
          path: paths.TELEGRAM_URL,
          isExternal: true,
        },
        {
          title: t`discord`,
          path: paths.DISCROD_URL,
          isExternal: true,
        },
      ],
    },
    {
      name: t`community`,
      links: [
        {
          title: t`get-started`,
          path: paths.COMMUNITY,
          isExternal: false,
        },
        {
          title: t`createbase`,
          path: paths.CREATE_BASE,
          isExternal: true,
        },
        {
          title: t`dao`,
          path: paths.DAO,
          isExternal: true,
        },

        {
          title: t`forums`,
          path: paths.FORUM,
          isExternal: true,
        },
      ],
    },
    {
      name: t`learn`,
      links: [
        {
          title: t`splits-roy`,
          path: paths.ROYALTIES,
          isExternal: false,
        },
        {
          title: t`why-near`,
          path: paths.WHY_NEAR,
          isExternal: true,
        },
        {
          title: t`why-arweave`,
          path: paths.WHY_ARWEAVE,
          isExternal: true,
        },
        {
          title: t`smart-contract`,
          path: paths.CONTRACT,
          isExternal: false,
        },
      ],
    },
  ]

  const basepath = router.pathname.split('/')[1]

  return (
    <Container>
      <FooterContent>
        <SocialButtons color={colors.DARK2} />
        <Content>
          <Copy color={colors.DARK2}>
            © {new Date().getFullYear()} Mintbase Inc.
          </Copy>
          <Copy color={colors.DARK2}>{t`lisbon`}, Portugal</Copy>
        </Content>
      </FooterContent>
      <RightButtons>
        {items.map(({ name, links }, i) => (
          <ButtonColumn key={name}>
            <SmallerHeader>{name}</SmallerHeader>
            {links.map(({ title, path, isExternal }) => {
              if (isExternal) {
                return (
                  <FooterLink
                    target="_blank"
                    href={path}
                    key={`${name}${path}-${i}`}
                  >
                    {title}
                  </FooterLink>
                )
              }

              return (
                <Link href={path} key={`${name}-${path}-${i}`} passHref>
                  <FooterInternalLink>{title}</FooterInternalLink>
                </Link>
              )
            })}
          </ButtonColumn>
        ))}
      </RightButtons>
    </Container>
  )
}

export default Footer
