import BN from 'bn.js'

export enum ThingSize {
  micro = '150px',
  small = '200px',
  medium = '300px',
  normal = '400px',
}

export const PADDING_XXS: '0.2rem' = '0.2rem'
export const PADDING_XS: '0.5rem' = '0.5rem'
export const PADDING_SM: '0.8rem' = '0.8rem'
export const PADDING_SMD: '1rem' = '1rem'
export const PADDING_MD: '1.5rem' = '1.5rem'
export const PADDING_MDL: '2rem' = '2rem'
export const PADDING_LG: '2.5rem' = '2.5rem'
export const PADDING_XL: '4rem' = '4rem'

export const HEADER_IMAGE_WIDTH = 2500
export const HEADER_IMAGE_HEIGHT = 910

export const SCREEN_SM: '700px' = '700px'

export const GRID_XS = 335
export const GRID_SM = 600
export const GRID_MD = 768
export const GRID_LG = 992
export const GRID_XL = 1140

export const SCREEN_MD_RAW = 950
export const SCREEN_XL_RAW = 1365
export const SCREEN_XL = `${SCREEN_XL_RAW}px`

export const POLLING_INTERVAL = 5800

export const FREE_LIMIT = 100000000

export const SMALLER_MAX_THING_WIDTH = '150px'
export const MAX_THING_WIDTH = '420px'
export const ZERO = new BN('0')
export const MAX_GAS = new BN('300000000000000')

export const iconWidth = 32

export const isMobile = () => {
  if (typeof navigator === 'undefined') {
    return null
  }
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true
  }
  // return false;
}

export const CONTAINER_ANNIMATION = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
}

export const ITEM_ANNIMATION = {
  hidden: { y: 15, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}
