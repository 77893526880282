import React from 'react'
import styled from 'styled-components'
import Head from 'next/head'
import Footer from 'components/Footer'
import * as styles from 'styles'
import { Copy, List, Header1, HeaderThin } from 'components/Typography'
import { useApp } from 'components/AppContext'
import { Telegram } from '@styled-icons/fa-brands/Telegram'
import { BlankA } from 'components/Buttons'
import { Discord } from '@styled-icons/boxicons-logos/Discord'
import { Github } from '@styled-icons/boxicons-logos/Github'
import { Typescript } from '@styled-icons/simple-icons/Typescript'
import { Npm } from '@styled-icons/fa-brands/Npm'
import { Button } from 'components/Buttons'
import { Code } from '@styled-icons/feather/Code'
import ClickableBox from 'components/ClickableBox'
import * as paths from 'content/paths'
import { Graphql } from '@styled-icons/simple-icons/Graphql'
import { Tools } from '@styled-icons/entypo/Tools'
import Link from 'next/link'
import { Eyeglasses } from '@styled-icons/bootstrap/Eyeglasses'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.DARK};
  @media (max-width: ${styles.GRID_SM}px) {
    padding: 0;
    overflow: scroll;
    padding-bottom: ${styles.PADDING_XL};
  }
`

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: ${styles.PADDING_MD};
  @media (max-width: ${styles.GRID_SM}px) {
    /* align-items: center; */
    margin-bottom: ${styles.PADDING_MD};
    padding: 0;
    padding-top: ${styles.PADDING_MD};
  }
`

const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.DARK4};
  padding: ${styles.PADDING_XL};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    display: none;
  }
`

const Boxes = styled.div`
  display: flex;
  padding: ${styles.PADDING_MD};
  flex-grow: 1;
  flex-wrap: wrap;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
  }
`

const SmallHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: ${styles.PADDING_XL};
  flex-grow: 1;
  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.DARK3};
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
    flex-direction: column;
  }
`

const CopyUri = styled(Copy)`
  display: flex;
  color: ${({ theme }) => theme.DARKER};
  background-color: ${({ theme }) => theme.DARK1};
  padding: ${styles.PADDING_SM};
  box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.17) inset;
  -webkit-box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.17) inset;
  -moz-box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.17) inset;
`

const Split = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 30%;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

const BulkCopy = styled(Copy)`
  flex-grow: 1;
  max-width: 550px;
`

const mainChannels = [
  {
    Icon: Eyeglasses,
    description: 'Getting Started',
    url: paths.DEV_DOCS,
    buttonText: 'Docs',
  },
  {
    Icon: Npm,
    description: 'npm install mintbase',
    url: paths.NPM,
    buttonText: 'code',
  },
  {
    Icon: Typescript,
    description: 'Typescript',
    url: paths.TYPESCRIPT,
    buttonText: 'Types docs',
  },
  {
    Icon: Tools,
    description: 'Create Mintbase App',
    url: paths.CREATE_MINTBASE_APP,
    buttonText: 'Quick Code Foundation',
  },
  {
    Icon: Github,
    description: 'Open Source',
    url: paths.GITHUB,
    buttonText: 'Code',
  },
  {
    Icon: Discord,
    description: '1,824 members',
    url: paths.DISCORD,
    buttonText: 'Support / Dev ',
  },

  {
    Icon: Telegram,
    description: 'Build on Mintbase',
    url: paths.TELEGRAM_DEV,
    buttonText: 'Fastest Answers',
  },
  {
    Icon: Code,
    description: 'NFT Standard',
    url: paths.NEP171,
    buttonText: 'NEP 171',
  },
]

const Maintenance = (props) => {
  const { colors, network } = useApp()
  return (
    <Page>
      <Head>
        <title>maintenance mode</title>
      </Head>
      <Header>
        <HeaderThin size="3rem" bottom={styles.PADDING_MD}>
          Down for Upgrades
        </HeaderThin>
        <Copy>Estimated ~3 or 4 hours</Copy>
      </Header>
      <Main>
        <SmallHeader>
          <Link href={paths.NPM}>
            <Split>
              <Tools width={83} color={colors.DARK1} />
            </Split>
          </Link>
          <Split>
            <div>
              <Header1 color={colors.DARK1}>Working on...</Header1>

              <ul>
                <List>Remove need for social login</List>
                <List>Auction notifications</List>
                <List>
                  Each NEAR account ID will have independent identification
                </List>
              </ul>
            </div>
          </Split>
        </SmallHeader>

        <Boxes>
          {mainChannels.map((channel, i) => (
            <ClickableBox {...channel} key={`dev-${i}`} />
          ))}
        </Boxes>
      </Main>
      <Footer />
    </Page>
  )
}

export default Maintenance
