import * as paths from 'content/paths'
import { NetworkConnection } from 'types/base'
import { Chain, Network } from 'mintbase'

export enum Networks {
  ethereum = 'ethereum',
  mainnet = 'mainnet',
  testnet = 'near',
}

export const getNetworkDetails = (headers): NetworkConnection => {
  if (!headers || !headers.host) {
    return null
  }

  const subdomain = headers.host.split('.')[0]

  if (subdomain === 'testnet' || subdomain === 'localhost:3000' || subdomain === 'dev') {
    return {
      graphUri: paths.GRAPH_TESTNET,
      network: Networks.testnet,
      subdomain: subdomain,
      host: headers.host,
      networkName: Network.testnet,
      explorer: paths.NEAR_EXPLORER_TESTNET,
      rpc: paths.NEAR_TESTNET_RPC,
      chain: Chain.near,
      explorerUri: paths.TESTNET_EXPLORER_URI,
    }
  }

  return {
    graphUri: paths.GRAPH_MAINNET,
    network: Networks.mainnet,
    subdomain: subdomain[0],
    host: headers.host,
    networkName: Network.mainnet,
    explorer: paths.NEAR_EXPLORER_MAINNET,
    rpc: paths.NEAR_MAINNET_RPC,
    chain: Chain.near,
    explorerUri: paths.MAINNET_EXPLORER_URI,
  }
}
