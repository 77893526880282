import React, { useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { database } from '../services/db'
import * as collections from '../services/collections'
import { HAS_TOKEN } from 'services/queries'
import { useLazyQuery } from '@apollo/client'
import {
  useApp,
  useDispatchApp,
  SET_ERROR,
  SET_ETH_PRICE,
  SET_PROVIDER,
  SET_TOKEN_MOD,
  SET_TOKEN_STORE_DEPLOYER,
} from './AppContext'
import { useHandleNearTransactions } from 'utils/useHandleNearTransactions'

import { useRouter } from 'next/router'
import styled from 'styled-components'
import * as styles from '../styles'
import { Header1, Subheader1 } from './Typography'
import { setAdminCookie } from 'utils/auth/userCookies'

const Page = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.DARK_ALPHA};
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  max-width: 750px;
  background-color: ${({ theme }) => theme.DARK3};
  -webkit-box-shadow: 0px 10px 21px -9px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 10px 21px -9px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 10px 21px -9px rgba(0, 0, 0, 0.32);
  align-items: center;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.GRID_SM}px) {
    flex-direction: column;
    max-width: 90%;
  }
`

const Wrapper: any = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`

const Content = styled.div``

interface LoaderProps {
  isLoading: boolean
  message: string | null
}
export const Loader = () => {
  const { loaderMessage, colors, account, user, screenWidth } = useApp()
  const dispatch = useDispatchApp()
  const router = useRouter()

  const { error: nearError } = useHandleNearTransactions()

  const show = loaderMessage !== null

  const styleProps = useSpring({
    transform: show ? `scaleX(1)` : `scaleX(0)`,
    opacity: show ? 1 : 0,
  })

  const [loadMod, mod] = useLazyQuery(HAS_TOKEN, {
    variables: { ownerId: account, thingId: process.env.NEXT_PUBLIC_MOD_TOKEN },
  })

  // const [loadStoreDeployer, storeDeployer] = useLazyQuery(HAS_TOKEN, {
  //   variables: {
  //     ownerId: account,
  //     thingId: process.env.NEXT_PUBLIC_STORE_DEPLOYER_TOKEN,
  //   },
  // })

  /*useEffect(() => {
    dispatch({
      type: SET_ERROR,
      payload: `We're currently under maintenance adding the final new NEAR NFT speck. Back latest by Friday.`,
    })
  }, [])*/

  const getGasPrice = async () => {
    try {
      const nearPriceData = await fetch(
        'https://api.binance.com/api/v3/ticker/price?symbol=NEARUSDT'
      )

      const nearPrice = await nearPriceData.json()

      dispatch({
        type: SET_ETH_PRICE,
        payload: {
          ethPrice: 1,
          gasPrice: 1,
          nearPrice: Number(nearPrice.price),
        },
      })
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: 'Error: Could not get ETH/Gas Price Data',
      })
    }
  }

  const getUser = async () => {
    // database
    //   .collection(collections.USER)
    //   .where('addressLowerCase', '==', account)
    //   .onSnapshot(function (querySnapshot) {
    //     if (!querySnapshot.docs[0]) {
    //       return
    //     }
    //     const doc = querySnapshot.docs[0].data()
    //     if (doc.email) {
    //       dispatch({
    //         type: SET_PROVIDER,
    //         payload: { email: doc.email, emailVerified: doc.verified },
    //       })
    //       return
    //     }
    //     console.log('Current cities in CA: ', doc)
    //   })
    // database
    //   .collection(collections.METER)
    //   .where('userAddress', '==', account)
    //   .onSnapshot(function (querySnapshot) {
    //     if (!querySnapshot.docs[0]) {
    //       return
    //     }
    //     const doc = querySnapshot.docs[0].data()
    //     dispatch({
    //       type: SET_PROVIDER,
    //       payload: { meter: doc },
    //     })
    //     return
    //   })
  }

  const placeInLine = async () => {
    const inlineDoc = await database
      .collection(collections.LINE)
      .doc(user.id)
      .get()

    if (inlineDoc.exists) return

    await database.collection(collections.LINE).doc(user.id).set({
      createdAt: Date.now(),
      email: user.email,
      uid: user.id,
      account: account,
    })
  }

  useEffect(() => {
    if (!account) return
    if (!account) return

    loadMod()
    // loadStoreDeployer()
  }, [account])

  useEffect(() => {
    if (!mod.called) return

    if (!account || !user) return

    const isStoreDeployer = true //storeDeployer?.data?.token?.length > 0
    const isMod = mod.data && mod.data.token && mod.data.token.length > 0

    dispatch({
      type: SET_TOKEN_MOD,
      payload: { isMod: isMod },
    })

    dispatch({
      type: SET_TOKEN_STORE_DEPLOYER,
      payload: { isStoreDeployer: isStoreDeployer },
    })

    setAdminCookie({ isMod, isStoreDeployer })

    if (!isMod && !isStoreDeployer) {
      placeInLine()
    }
  }, [user, account, mod])

  useEffect(() => {
    if (account) {
      getUser()
    }
  }, [account])

  useEffect(() => {
    // dispatch({
    //   type: SET_ERROR,
    //   payload: 'Transactions will not work today, upgrading our indexer!',
    // })
    getGasPrice()
  }, [])
  useEffect(() => {
    const isEmbeded = router.pathname.split('/')[1] === 'embed'
    dispatch({ type: SET_PROVIDER, payload: { isEmbeded } })
  }, [router.pathname])

  const reportWindowSize = () => {
    dispatch({
      type: SET_PROVIDER,
      payload: {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      },
    })
  }

  if (typeof window !== 'undefined') {
    useEffect(() => {
      window.addEventListener('resize', reportWindowSize)

      if (screenWidth === null) {
        dispatch({
          type: SET_PROVIDER,
          payload: {
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
          },
        })
      }
    }, [])
  }

  return (
    <Wrapper style={styleProps}>
      {show && (
        <Page>
          <Container>
            <Content>
              <Header1 color={colors.OFF_WHITE}>{"It's Working 🤖"}</Header1>
              {loaderMessage && (
                <Subheader1 color={colors.DARK2}>{loaderMessage}</Subheader1>
              )}
            </Content>
          </Container>
        </Page>
      )}
    </Wrapper>
  )
}

export default Loader
