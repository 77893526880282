module.exports = {
  locales: ['en', 'pt', 'zh'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'footer', 'humanMenu'],
    '/': ['home'],
    '/404': ['404'],
    '/feed': ['onboarding'],
    '/auth': ['auth'],
    'rgx:/human/[uid]*': ['human']
  },
  loadLocaleFrom: async (lang, ns) => {
    const defaultLocale = await import(`./locales/en/${ns}.json`).then(
      (m) => m.default
    )

    const locale = await import(`./locales/${lang}/${ns}.json`).then(
      (m) => m.default
    )

    return { ...defaultLocale, ...locale }
  },
}
