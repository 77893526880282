import React from 'react'
import styled from 'styled-components'
import * as styles from '../styles'
import * as paths from 'content/paths'
import { Twitter } from '@styled-icons/icomoon/Twitter'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Email } from '@styled-icons/material/Email'
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin'
import { Telegram } from '@styled-icons/boxicons-logos/Telegram'
import { Discord } from '@styled-icons/boxicons-logos/Discord'
import { Medium } from '@styled-icons/boxicons-logos/Medium'
import { Github } from '@styled-icons/boxicons-logos/Github'
interface SocialButtonsProps {
  color?: string
}

export const iconSize = 25

const SocialIcons = styled.div`
  display: flex;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

const IconContainer = styled.a`
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
  margin-right: ${styles.PADDING_SM};
  border: 1px solid ${({ theme }) => theme.DARK3};
  background-color: ${({ theme }) => theme.DARK4};
  padding: ${styles.PADDING_XS};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    margin-right: ${styles.PADDING_XXS};
    margin-bottom: ${styles.PADDING_XXS};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.GREEN};
    background-color: ${({ theme }) => theme.DARKER};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }
`

const TwitterIcon = styled(Twitter)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`

const InstagramIcon = styled(Instagram)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`
const EmailIcon = styled(Email)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`

const TelegramIcon = styled(Telegram)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`
const LinkedinIcon = styled(Linkedin)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`
const MediumIcon = styled(Medium)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`

const DiscordIcon = styled(Discord)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`

const GithubIcon = styled(Github)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`

const SocialButtons = ({ color }: SocialButtonsProps) => {
  return (
    <SocialIcons>
      {/* <IconContainer
        color={color}
        href="mailto:support@mintbase.io"
        target="_blank"
      >
        <EmailIcon width={iconSize} />
      </IconContainer> */}
      <IconContainer color={color} href={paths.TWITTER} target="_blank">
        <TwitterIcon width={iconSize} />
      </IconContainer>
      <IconContainer color={color} href={paths.INSTAGRAM} target="_blank">
        <InstagramIcon width={iconSize} />
      </IconContainer>
      <IconContainer color={color} href={paths.TELEGRAM_URL} target="_blank">
        <TelegramIcon width={iconSize} />
      </IconContainer>
      <IconContainer color={color} href={paths.LINKEDIN} target="_blank">
        <LinkedinIcon width={iconSize} />
      </IconContainer>
      <IconContainer color={color} href={paths.MEDIUM} target="_blank">
        <MediumIcon width={iconSize} />
      </IconContainer>
      <IconContainer color={color} href={paths.DISCORD} target="_blank">
        <DiscordIcon width={iconSize} />
      </IconContainer>

      <IconContainer color={color} href={paths.GITHUB} target="_blank">
        <GithubIcon width={iconSize} />
      </IconContainer>
    </SocialIcons>
  )
}
export default SocialButtons
