import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useSpring, animated } from 'react-spring'
import * as styles from 'styles'
import * as paths from 'content/paths'
import firebase from 'firebase/app'
import ChangeLanguage from 'components/ChangeLanguage'
import {
  useApp,
  useDispatchApp,
  LOGOUT,
  CLOSE_DOOR,
  TOGGLE_LOGOUT,
  SET_WALLET_CONNECT,
  TOGGLE_DOOR,
} from './AppContext'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import ThemeToggle from './ThemeToggle'

const minWidth = 350

const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  padding-top: ${styles.PADDING_XL};
  position: fixed;
  z-index: 9;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.DARKER};
  min-width: ${minWidth}px;
  transform-origin: top left;
  filter: ${styles.dropShadow};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    min-width: 60vw;
  }
`

const ABlank = styled.a`
  text-decoration: none;
`

const Header = styled.div`
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  padding-right: ${styles.PADDING_SM};
  color: ${({ theme }) => theme.OFF_WHITE};
  font-size: 1.6em;
  &:hover {
    cursor: pointer;
  }
`

const DesktopContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: space-between;
  color: ${({ theme }) => theme.OFF_WHITE};
`

const BottomStuff = styled.div`
  display: flex;
  padding: ${styles.PADDING_SM};
`

const CloseButton = styled.div`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding-right: ${styles.PADDING_SM};
  padding-left: ${styles.PADDING_SM};
  color: ${({ theme }) => theme.ACCENT};
  font-size: 1.6em;
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding-top: ${styles.PADDING_MD};
  /* align-content: center; */
`
interface ButtonProps {
  selected?: boolean
  onClick?: (any?: any) => void
}

const Button = styled.div<ButtonProps>`
  padding-left: ${styles.PADDING_MD};
  padding-top: ${styles.PADDING_MD};
  color: ${({ theme }) => theme.DARK1};
  border-bottom: 1px solid ${({ theme }) => theme.DARK4};

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.DARK4};
  }
  padding-bottom: ${styles.PADDING_MD};
  text-decoration: none;
  margin-right: 1px;
  font-weight: 300;
  font-size: ${({ size }: any) => size || '1em'};
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  &:hover {
    background-color: ${({ theme }) => theme.DARK};
    border-right: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }
`

const DesktopNav = () => {
  const { stores, isLoggedOut, navOpen, colors, user, wallet } = useApp()

  const router = useRouter()
  const dispatch = useDispatchApp()

  const [storeId, setStoreId] = useState('')
  const { t } = useTranslation('common')

  const goNext = (path: string) => {
    dispatch({ type: TOGGLE_DOOR })
    router.push(path)
  }

  const basepath = router.pathname.split('/')[1]

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut()

      if (wallet) wallet.disconnect()
      dispatch({ type: LOGOUT })

      router.push(paths.HOME)

      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isLoggedOut === true) {
      dispatch({ type: TOGGLE_LOGOUT })
    }
  }, [isLoggedOut])

  useEffect(() => {
    if (stores && stores.length) {
      setStoreId(stores[0].id)
    }
  }, [stores])

  const styleProps = useSpring({
    transform: navOpen
      ? `scaleX(0px, 0px)`
      : `translate(${-minWidth * 2}px, 0px)`,
    opacity: navOpen ? 1 : 0.9,
  })

  const goNextAndClose = (path: string) => {
    dispatch({ type: TOGGLE_DOOR })
    router.push(path).then(() => window.scrollTo(0, 0))
  }

  return (
    <Wrapper style={styleProps}>
      <DesktopContainer>
        <Header>
          <CloseButton onClick={() => dispatch({ type: TOGGLE_DOOR })}>
            <styles.CloseX size={50} color={colors.OFF_WHITE} />
          </CloseButton>
        </Header>

        <Buttons>
          {!user && (
            <Button selected={basepath === ''} onClick={() => goNext(`/`)}>
              {t`home`}
            </Button>
          )}

          {stores.length > 0 && (
            <Button
              selected={basepath === 'store'}
              onClick={() => goNextAndClose(`/store/${storeId}/mint`)}
            >
              {t`build`}
            </Button>
          )}
          <Button
            selected={basepath === paths.MARKET}
            onClick={() => goNextAndClose(paths.MARKET)}
          >
            {t`market`}
          </Button>

          {user && (
            <>
              <Button
                selected={basepath === paths.FEED}
                onClick={() => goNextAndClose(paths.FEED)}
              >
                {t`dashboard`}
              </Button>
              <Button
                selected={basepath === 'profile'}
                onClick={() => goNextAndClose(`/human/${user.walletAddress}`)}
              >
                {t`profile`}
              </Button>
              <Button
                selected={basepath === 'human'}
                onClick={() =>
                  goNextAndClose(`/human/${user.walletAddress}/owns`)
                }
              >
                {t`owned`}
              </Button>
            </>
          )}
          {!user && (
            <>
              <Button
                selected={basepath === paths.COMMUNITY}
                onClick={() => goNextAndClose(paths.COMMUNITY)}
              >
                {t`community`}
              </Button>
              <Button
                selected={basepath === paths.DEVELOPERS}
                onClick={() => goNextAndClose(paths.DEVELOPERS)}
              >
                {t`developers`}
              </Button>
            </>
          )}
          <ABlank href={paths.DOCS} target="_blank">
            <Button>{t`docs`}</Button>
          </ABlank>

          {/* <Button onClick={() => linkTo(paths.DOCS)}>{t`docs`}</Button> */}
          {!user && (
            <Link
              href={{
                pathname: paths.AUTH,
                query: { redirectURL: paths.FEED },
              }}
            >
              <Button>{t`sign-in`}</Button>
            </Link>
          )}
          {user && <Button onClick={handleLogout}>{t`logout`}</Button>}
        </Buttons>
        <BottomStuff>
          {user && <ThemeToggle />}
          {/* <ChangeLanguage /> */}
        </BottomStuff>
      </DesktopContainer>
    </Wrapper>
  )
}

export default DesktopNav
