/**
 * 1. Add a slider
 * 2. Add key events like ESC to dismiss modal
 */

import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Cropper from 'react-easy-crop'

import { getCroppedImage } from 'utils/image'

import * as styles from 'styles'
import { CloseButton } from 'components/TransferModal'
import { WideButton } from 'components/Buttons'
import { Header1 } from './Typography'
import {
  CANCEL_CROP_IMAGE,
  FINISH_CROP_IMAGE,
  useApp,
  useDispatchApp,
} from 'components/AppContext'

const MintButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const ballWidth = 20

export const Container = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.DARK_ALPHA};
  z-index: 3;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
  }
`

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35em;
  background-color: rgba(255, 255, 255, 0.95);
  position: relative;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1));
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    max-height: 89vh;
    overflow: scroll;
  }
`

const EditContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

const CropContainer = styled.div`
  position: relative;
  align-items: center;
  width: 256px;
  height: 256px;
`

const Row = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: ${styles.DARK2};
  outline: none;

  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${ballWidth}px;
    height: ${ballWidth}px;
    border-radius: 50%;
    background: ${styles.DARK};
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: ${ballWidth}px;
    height: ${ballWidth}px;
    border-radius: 50%;
    background: ${styles.DARK};
    cursor: pointer;
  }
`

const MySlider: any = styled(Slider)`
  margin-top: ${styles.PADDING_MD};
  max-width: 256px;
`

const CroppingModal = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const {
    croppingImage,
    isCroppingImage,
    screenWidth,
    cropCallback,
    cropAspectRatio,
  } = useApp()
  const dispatch = useDispatchApp()

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleChangeAmount = (event: any) => {
    setZoom(event.target.value)
  }

  const reset = () => {
    setZoom(1)
    setCrop({ x: 0, y: 0 })
  }

  const handleClose = () => {
    dispatch({ type: CANCEL_CROP_IMAGE })
    reset()
  }

  const handleApply = async () => {
    const image = await getCroppedImage(croppingImage, croppedAreaPixels, 0)
    cropCallback(image)

    dispatch({ type: FINISH_CROP_IMAGE })
    reset()
  }

  const styleProps = useSpring({
    transform: isCroppingImage
      ? `translate(0px, 0px)`
      : `translate(${-screenWidth}px, 0px)`,
    opacity: isCroppingImage ? 1 : 0,
  })

  return (
    <Container style={styleProps}>
      <EditContainer>
        <CloseButton onClick={handleClose}>
          <styles.CloseX size={40} />
        </CloseButton>
        <EditContent>
          <Row>
            <Header1>Crop media</Header1>
          </Row>
          <Row>
            <CropContainer>
              <Cropper
                image={croppingImage}
                crop={crop}
                zoom={zoom}
                aspect={cropAspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
              />
            </CropContainer>
          </Row>
          <Row>
            <MySlider
              type="range"
              min={1}
              max={2}
              step={0.1}
              value={zoom}
              onChange={handleChangeAmount}
            />
          </Row>
        </EditContent>
        <MintButtons>
          <WideButton onClick={handleApply}>Apply</WideButton>
        </MintButtons>
      </EditContainer>
    </Container>
  )
}

export default CroppingModal
