import { AppContextInterface } from '../components/AppContext'
import { Dispatch } from 'react'
import { Network, Chain } from 'mintbase'
export * from './indexer'

enum Protocol {
  http = 'http',
  https = 'http',
}

export interface NetworkConnection {
  graphUri: string
  network: string
  subdomain: string
  host: string
  networkName: Network
  chain: Chain
  explorer: string
  rpc: string
  explorerUri: string
}

export interface PageProps {
  network: NetworkConnection
}

export enum Flagged {
  banned = 'banned',
  nsfw = 'nsfw',
}
// Hasura Duplicate!

// End Hasura Duplicate!

export interface StoreSettings {
  headerImage: string | null
  displayName: string | null
  description: string | null
  twitter: string | null
  website: string | null
  symbol: string | null
  minters: string[]
  owner: string
  confirmed: Date | null
  createdAt: Date | null
  contract: string | null
  fsDocumentId: string
  defaultRoyalty?: { address?: string }
  defaultSplitRevenue?: { address?: string; amount?: number }
}

export interface DefaultRoyalty {
  account: string
  amount: number
}

export interface ListQuery {
  createdAt: string
  price: string
  tokenKey: string
  ownerId: string
  groupId: string
  autotransfer: boolean
  currentOfferId: string
  removedAt: string
  withdrwalEnabled: boolean
  token: {
    id: string
    minter: string
    splits: {
      account: string
      percent: string
      id: string
    }[]
    royaltys: {
      account: string
      percent: string
    }[]
  }
}

export interface Store {
  tokens: any
  id: string
  name: string
  symbol: string
  totalSupply: string
  burned: boolean
  owner: string
  things: Thing[]
  approvers: User[]
  timestamp: string
  transferCount: number
  valueCount: number
  boughtCount: number
  tokenCount: number
  storeName?: string
}

export interface Stat {
  topText: String
  bottomText: String
}

export interface Token {
  id: string
  price: string
  state: string
  metaId: string
  burned: boolean
  store: Store
  ownerId?: string
}

export interface Thing {
  id?: string
  metaId?: string
  tokens: Token[]
  contract: string
  editable?: boolean
  forSale?: boolean
  mintedOn?: string
  minter?: string
  hide?: boolean
  loaded?: boolean
  store?: Store
  lastTransferred?: String
}

export interface StoreState {
  thingName: string
  setThreshold: number
  storeName: string
  amountToMint: string
  img: string
}

export enum UIMode {
  dark = 'dark',
  light = 'light',
}

// export interface User {
//   id: string
//   stores: Store[]
//   tokens: Token[]
// }

export interface User {
  email: string
  id: string
  profileImage: string
  username?: string
  follows: string[]
  headerImage: string
  followingCounter: number
  followersCounter: number
  mode: UIMode
  isFeatured: boolean
  profileImageSmall: string
  isAdmin: boolean
  siteUrl: string
  communityUrl: string
  displayName: string
  network: string
  docId: string
  walletAddress: string
  chain: string
  isVerified: boolean
  oldUserId?: string
}

export const baseUser: User = {
  email: '',
  id: '',
  profileImage: '',
  username: '',
  follows: [],
  headerImage: '',
  followingCounter: 0,
  followersCounter: 0,
  mode: UIMode.dark,
  isFeatured: false,
  profileImageSmall: '',
  isAdmin: false,
  siteUrl: '',
  communityUrl: '',
  displayName: '',
  network: '',
  docId: '',
  walletAddress: '',
  chain: '',
  isVerified: false,
}
export interface Bootstrap {
  contract: string
  boughtButtonTest: string
  finishedUrl?: string
  buttonText?: string
  twitterHandle?: string
  network?: string
  backgroundColor?: string
  hideAvailable?: boolean
  suggestedUrl: string
  initialized: () => void
  isTest: boolean
  openedNum: number
  client: any
}

export interface TransferThings {
  thingId: string
  userAddress: string
  isValid: boolean
}

interface DispatchPayload {
  type: string
  payload: any
}

export interface Reducer {
  state: AppContextInterface
  dispatch: Dispatch<DispatchPayload>
}

export interface AddedFile {
  isSecret: boolean
  hash?: string
  transactionId?: string
  fileType: string
}

export interface ThingMeta {
  id: string | null
  name: string
  tokenId: string
  image: string
  description: string
  price: string
  contractAddress: string
  minter: string
  priceFiat?: string
  hide?: boolean
  loaded?: boolean
}

export interface ThingValues {
  isLoading?: boolean
  name: string
  image: string
  description: string
  storeAddress?: string
  price: string
  id: string
  thingIds: string[]
  numOwned: string
  contractAddress: string
  burned?: boolean
  thingMetaId: string
  isForSale: boolean
  priceFiat?: string
  minter?: string
  hide?: boolean
  outLink?: string
  eventDate?: any
  symbol?: string
  mintedOn?: string
  fiatPrice?: string
  addedFiles?: AddedFile[]
  loaded?: boolean
  animation_url?: string
  document?: string
  lock?: string
}

export interface Profile {
  id: string
  name: string
  username: string
  displayName: string
  account: string
  description: string
  siteUrl: string
  twitterName: string
  communityUrl: string
  hasProfile: boolean
  headerImage: string
  profileImage: string
  image: string
  email: string
  verifiedAccounts: {
    twitter: any
  }
}

export interface Split {
  account: string | null
  amount: number
  isValid: boolean
  enabled: boolean
}

export interface StoreToken {
  createdAt: string
  id: string
  list: {
    autotransfer: boolean
    groupId: string
    price: number
    removedAt: string | null
  }
  minter: string
  ownerId: string
  royaltys: {
    account: string
    id: string
    txId: string
  }[]
  splits: {
    account: string
    id: string
    txId: string
  }[]
  thing: {
    id: string
    metaId: string
  }
}
