export const USER: 'user' = 'user'
export const AGREEMENT: 'agreement' = 'agreement'
export const EARNED: 'earned' = 'earned'
export const STORE: 'store' = 'store'
export const FOLLOW: 'follow' = 'follow'
export const ACCOUNT: 'account' = 'account'
export const LAST_TRANSACTION: 'lastTransaction' = 'lastTransaction'
export const STORE_NAME: 'storeName' = 'storeName'
export const THING: 'thing' = 'thing'
export const TRANSACTION: 'transaction' = 'transaction'
export const COMPANY: 'company' = 'company'
export const LINE: 'line' = 'line'
export const CONTRACT: 'contract' = 'contract'
export const ROLE: 'role' = 'role'
export const ABI: 'abi' = 'abi'
export const AVAILABLE: 'available' = 'available'
export const CLICKS: 'click' = 'click'
export const FEATURED: 'featured' = 'featured'
export const FEATURED_HUMAN: 'featured-human' = 'featured-human'
export const ALL_FEATURED: 'all-featured' = 'all-featured'
export const REDEEMER: 'redeemer' = 'redeemer'
export const FLAGGED_MARKETS: 'flagged-markets' = 'flagged-markets'
export const REDEEMED: 'redeemed' = 'redeemed'
export const PURCHASED: 'purchased' = 'purchased'
export const CATEGORIES: 'categories' = 'categories'
export const METER: 'meter' = 'meter'
export const FACTORY_ABI: 'factory-abi' = 'factory-abi'
export const STATS: 'stats' = 'stats'
export const VOTE: 'vote' = 'vote'
export const CUSTOM_VOTE: 'custom-vote' = 'custom-vote'
export const CUSTOM_HOLDER: 'custom-holder' = 'custom-holder'
export const THING_CATEGORY: 'thing-category' = 'thing-category'
export const MINTED: 'minted' = 'minted'
export const ISSUED: 'issued' = 'issued'
export const FEATURED_HUMANS: 'featured-humans' = 'featured-humans'
export const DEVELOPER: 'developer' = 'developer'
export const STORAGE_STATS: 'storage-api-stats' = 'storage-api-stats'
export const IS_NSFW: 'is-nsfw' = 'is-nsfw'
export const FLAGGED_THING: 'flagged-thing' = 'flagged-thing'
