import { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import { DarkInput, Header1, Subheader1 } from 'components/Typography'
import { useApp } from './AppContext'
import useDebounce from './useDebounce'
import { XCircle } from '@styled-icons/feather/XCircle'
import { CheckCircle } from '@styled-icons/feather/CheckCircle'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: ${styles.PADDING_XS};
`

const Bar = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  padding: 0;
  margin: 0;
`

const CheckContainer = styled.div`
  display: flex;
  /* align-items: center; */
  position: absolute;
  top: ${styles.PADDING_XS};
  right: ${styles.PADDING_XS};
  /* flex-grow: 1; */
  /* justify-content: center; */
  /* background-color: ${({ theme }) => theme.OFF_WHITE} */
`

const WideDarkInput = styled(DarkInput)`
  flex-grow: 1;
`

interface TextCheckProps {
  label: string
  value: String | Number
  onValid: ({
    id,
    text,
    isValid,
  }: {
    id: String
    text: String
    isValid: boolean
  }) => void
  tokenId: String
}

const TextCheck = (props: TextCheckProps) => {
  const { network } = useApp()
  const [pass, setPass] = useState(false)

  const [searchTxt, setSearchTxt] = useState('')

  const checkIsTrue = async (str: string) => {
    if (!network) return

    const expressAuthorized = await fetch(network.rpc, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: 'dontcare2',
        method: 'query',
        params: {
          request_type: 'view_account',
          finality: 'final',
          account_id: str,
        },
      }),
    })

    const response = await expressAuthorized.json()

    if (response.error) {
      setPass(false)
      props.onValid({ id: props.tokenId, text: str, isValid: false })
      return
    }

    props.onValid({ id: props.tokenId, text: str, isValid: true })
    setPass(true)
  }

  const debouncedSearchTerm = useDebounce(searchTxt, 500)

  useEffect(() => {
    checkIsTrue(searchTxt)
  }, [debouncedSearchTerm])

  return (
    <Container>
      <Bar>
        <WideDarkInput
          type="text"
          placeholder="Account"
          onChange={(e) => setSearchTxt(e.target.value.toLowerCase())}
          value={searchTxt}
          spellCheck={false}
          autoComplete="off"
          bottom="0"
          top="0"
        />

        {searchTxt !== '' && (
          <CheckContainer>
            {!pass && <XCircle width={30} color={styles.DARK2} />}
            {pass && <CheckCircle width={30} color={styles.GREEN} />}
          </CheckContainer>
        )}
      </Bar>
    </Container>
  )
}

export default TextCheck
