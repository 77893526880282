import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { useApp, useDispatchApp, SET_MESSAGE } from './AppContext'
import { Copy } from './Typography'
import { Button } from './Buttons'
import * as styles from '../styles'

interface MessageProps {
  message?: string | null
  timeout?: number | null
}

const MessageContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  filter: ${styles.DROP_SHADOW};
  background-color: ${({ theme }) => theme.DARK3};
  border-bottom: 1px solid ${({ theme }) => theme.DARK2};
  z-index: 204;
`
export const FormContent = styled.form`
  display: flex;
  flex-grow: 5;
  flex-direction: column;
  padding: ${styles.PADDING_MD};
`

export const ExitButton = styled(Button)`
  flex-grow: 1;
  max-width: 200px;
  color: ${({ theme }) => theme.OFF_WHITE};
  background-color: ${({ theme }) => theme.DARK2};
  border: 1px solid ${({ theme }) => theme.DARK2};
`

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
`

const MessageModal = ({ timeout = 3000 }: MessageProps) => {
  const { colors, message } = useApp()
  const dispatch = useDispatchApp()

  const styleProps = useSpring({
    transform:
      message !== null ? 'translate(0px, 0px)' : 'translate(0px, -200px)',
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: SET_MESSAGE, payload: null })
    }, timeout)
    return () => clearTimeout(timer)
  }, [message])

  return (
    <Wrapper style={styleProps}>
      <MessageContainer>
        <FormContent>
          <Copy color={colors.OFF_WHITE}>{message}</Copy>
        </FormContent>
        <ExitButton
          onClick={() => dispatch({ type: SET_MESSAGE, payload: null })}
        >
          Got It
        </ExitButton>
      </MessageContainer>
    </Wrapper>
  )
}

export default MessageModal
