import styled from 'styled-components'
import * as styles from 'styles'

export const DEFAULT_FONT_SIZE_LG = 1.2

interface ButtonProps {
  background?: string
  type?: string
  onClick?: (any?: any, any2?: any) => any
  color?: string
  selected?: boolean
  size?: string
  onTouchStart?: () => void
  onTouchEnd?: () => void
  onMouseDown?: () => void
  onMouseUp?: () => void
  marginBottom?: string
}

export const ShareButton = styled.a`
  background: ${({ theme }) => theme.DARK3};
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  margin-right: ${styles.PADDING_SM};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    border-radius: 0%;
    margin-right: 0;
  }
`

export const MiddleButton = styled.div`
  display: inline-block;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.DARK2};
  justify-content: center;
  padding: ${styles.PADDING_SM};
  margin-right: ${styles.PADDING_SM};

  color: ${({ theme }) => theme.OFF_WHITE};
  font-size: ${({ size }: any) => size || '1.3em'};
  font-weight: 100;
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  margin-top: ${styles.PADDING_SM};
  font-size: 1.2rem;
  &:hover {
    background-color: ${({ theme }) => theme.DARK4};
    color: ${({ theme }) => theme.DARK1};
    border: 1px solid ${({ theme }) => theme.GREEN};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1rem;
  }
`

export const BlankA = styled.a`
  display: flex;
  text-decoration: none;
`
export const OutlineAButton = styled.a<ButtonProps>`
  text-decoration: none;
  display: block;
  border: none;

  padding: ${styles.PADDING_SM};
  background-color: transparent;
  text-align: center;
  color: ${({ color, theme }) => color || theme.DARK1};
  font-size: ${({ size }: any) => size || '1.1em'};
  font-weight: 300;
  /* align-items: center; */
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  margin: 0;
  background-color: ${({ theme }) => theme.DARK3};
  margin-bottom: ${({ marginBottom }: any) => marginBottom || 0};
  margin-right: ${({ marginRight }: any) => marginRight || 0};
  /* border: 1px solid ${styles.OFF_WHITE}; */
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.ALWAYS_WHITE};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1em;
  }
`

export const MainButton = styled.div<ButtonProps>`
  text-decoration: none;
  display: block;
  border: none;
  padding: ${styles.PADDING_SMD};
  text-align: center;
  color: ${({ color, theme }) => color || theme.DARK};
  font-size: ${({ size }: any) => size || '1.1em'};
  font-weight: 300;
  /* align-items: center; */
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  margin: 0;
  flex-grow: 1;
  margin-bottom: ${({ marginBottom }: any) => marginBottom || 0};
  margin-right: ${({ marginRight }: any) => marginRight || 0};
  border: 1px solid ${({ theme }) => theme.DARKER};
  background-color: ${({ theme }) => theme.DARK2};
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1em;
  }
`

export const OutlineButton = styled.div`
  text-decoration: none;
  display: block;
  border: none;
  padding: ${styles.PADDING_SM};
  text-align: center;
  color: ${({ color }: ButtonProps) => color || styles.OFF_WHITE};
  font-size: ${({ size }: any) => size || '1.1em'};
  font-weight: 300;
  /* align-items: center; */
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  margin: 0;
  flex-grow: 1;
  margin-bottom: ${({ marginBottom }: any) => marginBottom || 0};
  margin-right: ${({ marginRight }: any) => marginRight || 0};
  border: 1px solid ${styles.OFF_WHITE};
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1em;
  }
`

export const Submit = styled.input<ButtonProps>`
  display: flex;
  /* align-items: center; */
  text-decoration: none;
  display: block;
  border: none;
  padding: ${styles.PADDING_XS};
  min-width: 200px;
  align-self: flex-end;
  text-align: center;
  background-color: ${({ background, theme }) => background || theme.DARK};
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
  font-size: ${({ size }) => size || '1.3em'};
  font-weight: 100;
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  -webkit-appearance: none;
  border: 1px solid ${({ theme }) => theme.DARK2};
  &:hover {
    border: 1px solid ${({ theme }) => theme.GREEN};
    background-color: ${({ theme }) => theme.DARK};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1em;
    border-radius: 0px;
  }
`

export const Button = styled.div<ButtonProps>`
  text-decoration: none;
  display: flex;
  border: 1px solid ${({ theme }) => theme.DARK};
  justify-content: center;
  padding: ${styles.PADDING_SM};
  background-color: ${({ background, theme }) => background || theme.DARKER};
  text-align: center;
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
  font-size: ${({ size }: any) => size || '1.1em'};
  font-weight: 300;
  align-items: center;
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  user-select: none;
  margin: 0;
  &:hover {
    background-color: ${({ theme }) => theme.DARK4};
    color: ${({ theme }) => theme.DARK1};
    border: 1px solid ${({ theme }) => theme.GREEN};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1rem;
  }
`

export const ATag = styled.a<ButtonProps>`
  text-decoration: none;
  display: flex;
  border: 1px solid ${({ theme }) => theme.DARK};
  justify-content: center;
  padding: ${styles.PADDING_SM};
  background-color: ${({ background, theme }) => background || theme.DARK3};
  text-align: center;
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
  font-size: ${({ size }: any) => size || '1.1em'};
  font-weight: 300;
  align-items: center;
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  margin: 0;
  &:hover {
    background-color: ${({ theme }) => theme.DARK4};
    color: ${({ theme }) => theme.DARK1};
    border: 1px solid ${({ theme }) => theme.GREEN};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1rem;
  }
`

export const WideButton = styled(Button)`
  flex-grow: 1;
`
export const WideATag = styled(ATag)`
  flex-grow: 1;
`

interface AnchorButton {
  background?: string
  color?: string
  target: string
  href?: string
  download?: boolean
  size?: string
  padTop?: string
  padBotton?: string
}

export const RawAnchorButton = styled.a<AnchorButton>`
  background-color: ${({ background, theme }) => background || theme.DARK3};
  color: ${({ color, theme }) => color || theme.DARK2};
  font-size: ${({ size }: any) => size || '1.1em'};
  padding: ${styles.PADDING_SM};
  /* margin-top: ${styles.PADDING_MD};
  margin-bottom: ${styles.PADDING_SM}; */
  text-decoration: none;
  font-family: 'Raleway', Calibri, sans-serif;
  font-weight: 300;
  text-align: center;
  a:visited {
    color: ${({ color, theme }) => color || theme.OFF_WHITE};
  }
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1em;
  }
`

export const AnchorButton = styled.a<AnchorButton>`
  display: flex;
  background-color: ${({ background, theme }) => background || theme.DARK3};
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
  font-size: ${({ size }) => size || '1rem'};
  padding: ${styles.PADDING_SM};
  text-decoration: none;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.DARK};
  font-family: 'Raleway', Calibri, sans-serif;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  margin: 0;
  a:visited {
    color: ${({ color, theme }) => color || theme.OFF_WHITE};
  }
  &:hover {
    background-color: ${({ theme }) => theme.DARK4};
    color: ${({ theme }) => theme.DARK1};
    border: 1px solid ${({ theme }) => theme.GREEN};
    cursor: pointer;
  }
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1em;
  }
`

export const WideAnchorButton = styled(AnchorButton)`
  flex-grow: 1;
`

export const WhiteAnchoreButton = styled(AnchorButton)`
  background-color: ${({ theme }) => theme.OFF_WHITE};
  color: ${({ theme }) => theme.DARK};
  border: 1px ${({ theme }) => theme.DARK2} solid;
  &:hover {
    border: none;
    cursor: pointer;
  }
`

export const DropShadow = styled.div`
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
`

export const DropShadowImg = styled.img`
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
`

export const DropShadowA = styled.a`
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.1);
`
