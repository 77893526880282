import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import * as styles from 'styles'
import MintbaseLogo from 'components/MintbaseLogo'
import { Menu } from '@styled-icons/material/Menu'
import { Button } from 'components/Buttons'
import * as paths from 'content/paths'
import Stores from 'components/Stores'
import HoverMenu from 'components/HoverMenu'
import { HumanMenu, ResourcesMenu } from 'components/HumanMenu'
import { Search } from '@styled-icons/feather/Search'
import useTranslation from 'next-translate/useTranslation'
import AccountSignal from 'components/AccountSignal'
import {
  useApp,
  useDispatchApp,
  TOGGLE_LOGOUT,
  TOGGLE_DOOR,
  SET_WALLET_CONNECT,
} from 'components/AppContext'
import Link from 'next/link'
import ToggleNetworks from './ToggleNetworks'
import SearchBar from './SearchBar'

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  position: -webkit-sticky;
  flex: 1 0 auto;
  position: sticky;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.DARK3};
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.DARKER};
`

const Row = styled.div`
  display: flex;
`

const TopLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.DARKER};
  color: ${({ theme }) => theme.DARK2};
  margin-right: ${styles.PADDING_XS};

  border-bottom: 1px solid ${({ theme }) => theme.DARKER};
  &:hover {
    background-color: ${({ theme }) => theme.DARK};
    border-bottom: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  @media (max-width: ${styles.GRID_SM}px) {
    display: none;
  }
`
const RightBlock = styled.div`
  display: flex;
  color: ${({ theme }) => theme.OFF_WHITE};
  /* justify-content: space-between; */
  flex-grow: 1;
  align-self: flex-end;
  justify-content: flex-end;
  @media (max-width: ${styles.GRID_SM}px) {
    display: none;
  }
`

const MobileSearch = styled.div`
  display: none;
  @media (max-width: ${styles.GRID_SM}px) {
    display: flex;
    align-items: center;
  }
`

const MenuIcon = styled(Menu)`
  display: flex;
  flex-grow: 1;
  padding: ${styles.PADDING_SM};
`

const Block = styled.div`
  position: relative;
  display: flex;
`

const FirstNavBrick = styled.div`
  display: flex;
`

const BuilderButton = styled.a<{ selected: boolean }>`
  display: flex;
  padding-right: ${styles.PADDING_MD};
  padding-left: ${styles.PADDING_MD};
  color: ${({ theme, selected }) => (selected ? theme.OFF_WHITE : theme.DARK2)};
  border-bottom: 1px solid
    ${({ theme, selected }) => (selected ? theme.GREEN : theme.DARKER)};
  align-items: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-align: center;

  font-size: 1.1em;
  font-weight: 300;
  align-items: center;
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  margin: 0;
  &:hover {
    background-color: ${({ theme }) => theme.DARK};
    border-bottom: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    display: none;
  }
`

const LoginContainer = styled.div`
  display: flex;
  @media (max-width: ${styles.GRID_XS}px) {
    display: none;
  }
`
const LoginButton = styled(Button)`
  color: ${({ theme }) => theme.DARK1};
  background-color: ${({ theme }) => theme.DARKER};
  border-left: 1px solid ${({ theme }) => theme.DARK3};
  padding: ${styles.PADDING_MD};
`

const HumanButton = styled.a`
  display: flex;
  padding: ${styles.PADDING_SMD};
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.DARKER};
  &:hover {
    background-color: ${({ theme }) => theme.DARK};
    border-bottom: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
`

const SearchButton = styled.div`
  padding: ${styles.PADDING_SM};
`

const ImageHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
`

const Image: any = styled.img`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.DARK2};
  @media (max-width: ${styles.GRID_SM}px) {
    align-self: center;
  }
`

const TopNav = () => {
  const { stores, isLoggedOut, colors, user } = useApp()
  const [isMobileSearchEnabled, setIsMobileSearchEnabled] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inHover, setHover] = useState(false)

  const [inHoverRes, setHoverRes] = useState(false)

  const { t } = useTranslation('common')

  const router = useRouter()
  const dispatch = useDispatchApp()
  const [storeId, setStoreId] = useState('')

  const basepath = router.pathname.split('/')[1]

  useEffect(() => {
    if (isLoggedOut === true) {
      dispatch({ type: TOGGLE_LOGOUT })
      router.push('/').then(() => window.scrollTo(0, 0))
    }
  }, [isLoggedOut])

  useEffect(() => {
    if (stores && stores.length) {
      setStoreId(stores[0].id)
    }
  }, [stores])

  const handleSearchToggle = (isEnabled: boolean) => {
    setIsMobileSearchEnabled(isEnabled)
  }

  const minterUrl = `/store/${storeId}/mint`

  return (
    <Container>
      {!isMobileSearchEnabled && (
        <Row>
          <TopLogo onClick={() => dispatch({ type: TOGGLE_DOOR })}>
            <MenuIcon size={33} />
          </TopLogo>
          <Link href={user ? paths.FEED : paths.HOME} passHref>
            <HumanButton>
              <MintbaseLogo
                width={37}
                color={
                  basepath === '' || basepath === 'feed'
                    ? colors.OFF_WHITE
                    : colors.DARK2
                }
              />
            </HumanButton>
          </Link>
        </Row>
      )}
      <SearchBar onMobileSearchToggle={handleSearchToggle} />
      <Stores />
      <Wrapper>
        {!user && (
          <LoginContainer>
            <Link href={paths.MARKET} passHref>
              <BuilderButton selected={basepath === 'market'}>
                {t`market`}
              </BuilderButton>
            </Link>
            <Link href={paths.COMMUNITY} passHref>
              <BuilderButton selected={basepath === 'community'}>
                {t`community`}
              </BuilderButton>
            </Link>
            <Block
              onMouseEnter={() => setHoverRes(true)}
              onMouseLeave={() => setHoverRes(false)}
            >
              <BuilderButton selected={basepath === 'developers'}>
                {t`resources`}
              </BuilderButton>
              <HoverMenu uid={''} isVisible={inHoverRes} Menu={ResourcesMenu} />
            </Block>
          </LoginContainer>
        )}
      </Wrapper>

      {!user && !isMobileSearchEnabled && (
        <LoginContainer>
          <ToggleNetworks />
          <Link
            href={{
              pathname: paths.AUTH,
              query: { redirectURL: paths.FEED },
            }}
          >
            <LoginButton>{t`login-signup`}</LoginButton>
          </Link>
        </LoginContainer>
      )}
      {user && (
        <RightBlock>
          {stores.length > 0 && (
            <Link href={minterUrl} passHref>
              <BuilderButton selected={basepath === 'store'}>
                {t`build`}
              </BuilderButton>
            </Link>
          )}
          {stores.length === 0 && (
            <Link href={paths.DEPLOY}>
              <BuilderButton selected={basepath === 'store'}>
                {t`deploy-store`}
              </BuilderButton>
            </Link>
          )}

          <Link href={paths.MARKET} passHref>
            <BuilderButton selected={basepath === 'market'}>
              {t`market`}
            </BuilderButton>
          </Link>

          <Block
            onMouseEnter={() => setHoverRes(true)}
            onMouseLeave={() => setHoverRes(false)}
          >
            <BuilderButton selected={basepath === 'developers'}>
              {t`resources`}
            </BuilderButton>
            <HoverMenu
              uid={user.id}
              isVisible={inHoverRes}
              Menu={ResourcesMenu}
            />
          </Block>
          <FirstNavBrick>
            <ToggleNetworks />
            <AccountSignal />
            {user && (
              <Link href={`/human/${user.walletAddress}`} passHref>
                <HumanButton
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <ImageHolder>
                    <Image src={user.profileImage || '/profile/bob.jpg'} />
                  </ImageHolder>
                  <HoverMenu
                    uid={user.walletAddress}
                    isVisible={inHover}
                    Menu={HumanMenu}
                  />
                </HumanButton>
              </Link>
            )}
          </FirstNavBrick>
        </RightBlock>
      )}
    </Container>
  )
}

export default TopNav
