// https://docs.near.org/docs/roles/integrator/errors/introduction#near-platform-errors

import { useEffect, useState } from 'react'
import lodash from 'lodash'
import deepdash from 'deepdash'
import { useRouter } from 'next/router'
import { useApp, useDispatchApp, SET_ERROR } from 'components/AppContext'
import { fetchTransactionLastResult } from 'utils/near'
import { nearTransactionUri } from 'content/paths'

const _ = deepdash(lodash)

/**
 * TODO: add error codes
 *
 * const errorCodes = {
 *  1: "could not find that token",
 *  2: ...
 * }
 */

const SUCCESS = 'SuccessValue'
const FAILURE = 'Failure'

const useHandleNearTransactions = () => {
  const { account, network } = useApp()
  const router = useRouter()
  const dispatch = useDispatchApp()

  // guard against displaying multiple errors or the case there's a hash and userRejected code
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false)

  const [error, setError] = useState<number>()

  const { errorCode, transactionHashes } = router.query

  const hasSucceeded = (status) => {
    return status.hasOwnProperty(SUCCESS)
  }

  const displayError = (errorMessage: string, url?: string) => {
    if (errorDisplayed) return

    if (!!url) {
      dispatch({
        type: SET_ERROR,
        payload: { message: errorMessage, url: url },
      })
    } else {
      dispatch({ type: SET_ERROR, payload: errorMessage })
    }

    setErrorDisplayed(true)
  }

  const handleTransactionHash = async () => {
    const result = await fetchTransactionLastResult(
      transactionHashes as string,
      account
    )

    if (!hasSucceeded(result?.status)) {
      const status = result.status

      const failure = status[FAILURE]

      if (!failure) return

      const message = _.findDeep(failure, (value, key, parent) => {
        if (key === 'panic_msg') return value
      })

      if (!message?.value) {
        const key = Object.keys(failure) // ActionError, InvalidTxError, StorageError, BalanceMismatchError...
        const errorName = Object.keys(failure[key]?.kind)[0]

        displayError(
          `Your transaction failed with: ${errorName}`,
          nearTransactionUri(network?.explorer, transactionHashes as string)
        )
      } else {
        displayError(
          `Your transaction failed with: ${message?.value}`,
          nearTransactionUri(network?.explorer, transactionHashes as string)
        )
      }

      setError(1)
    }
  }

  useEffect(() => {
    if (!!errorCode) {
      displayError('Your transaction has failed or was rejected.')
      setError(1)
    }
  }, [])

  useEffect(() => {
    if (!account || !transactionHashes) return

    handleTransactionHash()
  }, [account, transactionHashes])

  return { error }
}

export { useHandleNearTransactions }
