import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  useApp,
  useDispatchApp,
  SET_WALLET_CONNECT,
} from 'components/AppContext'
import * as styles from 'styles'
import { Copy } from './Typography'
import Link from 'next/link'
import Spinner from 'components/Spinner'

const Container = styled.div`
  display: flex;
  flex: 1 0 0;
  /* align-items: center; */
`

const ConnectButton = styled.a`
  display: flex;
  padding-top: ${styles.PADDING_SM};
  padding-right: ${styles.PADDING_MD};
  padding-left: ${styles.PADDING_MD};
  justify-content: center;
  text-decoration: none;
  align-items: center;
  flex: 1 0 0;
  &:hover {
    background-color: ${({ theme }) => theme.DARK3};
    color: ${({ theme }) => theme.OFF_WHITE};
    cursor: pointer;
  }
`

const AccountText = styled(Copy)`
  font-size: 300;
  color: ${({ theme }) => theme.OFF_WHITE};
  margin-bottom: ${styles.PADDING_XXS};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${styles.PADDING_XXS};
`

const Circle = styled.div`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: ${({ color, theme }) => (color ? color : theme.ACCENT)};
  margin-right: ${styles.PADDING_SM};
  margin-top: ${styles.PADDING_XXS};
`

const WalletDetailsContainer = styled.div`
  display: flex;
`
const Status = styled.div`
  display: flex;
`

const AccountSignal = () => {
  const { user, colors, walletDetails, wallet } = useApp()
  const dispatch = useDispatchApp()

  const [connectedAccount, setConnectedAccount] = useState(null)

  const handleNearLogin = () => {
    return dispatch({
      type: SET_WALLET_CONNECT,
      payload: { needsWallet: true },
    })
  }

  useEffect(() => {
    if (!wallet?.isConnected()) {
      return setConnectedAccount(null)
    }

    setConnectedAccount(wallet?.activeAccount?.accountId)
  }, [walletDetails, wallet])

  return (
    <Container>
      {!wallet && (
        <ConnectButton>
          <Spinner />
        </ConnectButton>
      )}
      {wallet && connectedAccount && user && (
        <Link href={`/accounts`} passHref>
          <ConnectButton>
            <Content>
              <Status>
                <Circle color={colors.GREEN} />
                <WalletDetailsContainer>
                  <div>
                    <AccountText
                      color={colors.DARK1}
                      size="1em"
                      lineHeight="1em"
                    >
                      {connectedAccount}
                    </AccountText>
                    <Copy color={colors.DARK2} size="0.7em">
                      {walletDetails &&
                        `${walletDetails?.allowance} | ${walletDetails?.balance}`}
                    </Copy>
                  </div>
                </WalletDetailsContainer>
              </Status>
            </Content>
          </ConnectButton>
        </Link>
      )}
      {wallet && !connectedAccount && (
        <ConnectButton onClick={() => handleNearLogin()}>
          <Content>
            <Status>
              <Circle />
              <WalletDetailsContainer>
                <Copy color={colors.DARK1} size="1em">
                  Connect Wallet
                </Copy>
              </WalletDetailsContainer>
            </Status>
          </Content>
        </ConnectButton>
      )}
    </Container>
  )
}

export default AccountSignal
