import posed from 'react-pose'

import styled, { keyframes } from 'styled-components'

const coolBoxKeyframes = keyframes`
  0% {
    opacity: 0;
    
  }
  100% {
    opacity: 1;
  }
`

export const CoolBox = styled.div`
  animation-name: ${coolBoxKeyframes};
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`

export const Box = posed.div({
  exit: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10,
      mass: 0.6,
    },
  },
})

export const Box2 = posed.div({
  hidden: { y: 50, opacity: 0, display: 'none' },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10,
      mass: 0.1,
    },
  },
})

export const Box3 = posed.div({
  hiddener: { opacity: 0 },
  visibler: {
    opacity: 1,
  },
})

export const enterInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }

  to {
    transform: 1;
    transform: scale(1, 1);
  }
`

export default Box
