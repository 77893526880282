import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Link from 'next/link'
import * as styles from 'styles'
import { MenuProps } from 'components/HumanMenu'

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 70px;
  right: 15px;
  flex-direction: column;
  z-index: 5;
  margin-right: ${styles.PADDING_SM};
  filter: ${styles.dropShadow};
  min-width: 200px;
`

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.DARK4};
`

const triSize = '20px'

const Triangle = styled.div`
  align-self: flex-end;
  width: 0;
  height: 0;

  border-left: ${triSize} solid transparent;
  border-right: ${triSize} solid transparent;
  border-bottom: ${triSize} solid ${({ theme }) => theme.DARK4}; ;
`

const Button = styled.div`
  padding: ${styles.PADDING_MD};
  font-weight: 300;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.DARK3};
  }
  border-left: 1px solid ${({ theme }) => theme.DARK4};
  color: ${({ theme }) => theme.DARK1};
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  &:hover {
    background-color: ${({ theme }) => theme.DARKER};
    border-left: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }
`

const HoverMenu: React.FC<{
  uid: string
  isVisible: boolean
  Menu: React.FC<MenuProps>
}> = ({ uid, isVisible, Menu }) => (
  <AnimatePresence initial={false}>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Container>
          <Triangle />
          <Nav>
            <Menu uid={uid}>
              {({ url, name }) => (
                <Link href={url} key={`hover-${name}`}>
                  <Button>{name}</Button>
                </Link>
              )}
            </Menu>
          </Nav>
        </Container>
      </motion.div>
    )}
  </AnimatePresence>
)

export default HoverMenu
