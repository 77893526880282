import styled from 'styled-components'
import firebase from 'firebase/app'
import * as styles from 'styles'
import * as services from 'services'
import { Moon } from '@styled-icons/entypo/Moon'
import { Sun } from '@styled-icons/feather/Sun'
import {
  useApp,
  useDispatchApp,
  SET_DARK_MODE,
  SET_LIGHT_MODE,
} from './AppContext'
import { User, UIMode } from 'types/base'
import { useEffect } from 'react'

const Container = styled.div`
  display: flex;
  /* background-color: red; */
  align-items: center;
  margin-right: ${styles.PADDING_SM};
`
const Pill = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.DARK3};
  background-color: ${({ theme }) => theme.DARK1};
`
interface ButtonHolderProps {
  selected: boolean
}

const iconSize = 22
const ButtonHolder = styled.div<ButtonHolderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${styles.PADDING_XS};
  color: ${({ theme, selected }) => (selected ? theme.DARK1 : theme.DARK1)};
  background-color: ${({ theme, selected }) =>
    selected ? theme.DARK3 : theme.DARKER};
  &:hover {
    background-color: ${({ theme }) => theme.ACCENT};
    color: ${({ theme }) => theme.ALWAYS_WHITE};
    cursor: pointer;
  }
`

const ThemeToggle = () => {
  const { colors, user, isDark } = useApp()
  const dispatch = useDispatchApp()

  useEffect(() => {
    if (!user) return

    const { mode } = user

    dispatch({
      type: mode === 'light' ? SET_LIGHT_MODE : SET_DARK_MODE,
    })
  }, [user])

  const toggleTheme = async () => {
    const newMode = isDark ? UIMode.light : UIMode.dark

    await services.database
      .collection(services.USER)
      .doc(user.docId)
      .update({ mode: newMode })

    dispatch({
      type: isDark ? SET_LIGHT_MODE : SET_DARK_MODE,
    })
  }

  return (
    <Container>
      <Pill>
        <ButtonHolder onClick={toggleTheme} selected={isDark}>
          <Moon size={iconSize} />
        </ButtonHolder>
        <ButtonHolder onClick={toggleTheme} selected={!isDark}>
          <Sun size={iconSize} />
        </ButtonHolder>
      </Pill>
    </Container>
  )
}

export default ThemeToggle
