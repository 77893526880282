import { useState, useEffect } from 'react'
import styled, { ThemeConsumer } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Copy } from 'components/Typography'
import * as styles from 'styles'
import Link from 'next/link'
import { NetworkConnection } from 'types/base'
import { useApp } from './AppContext'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding: ${styles.PADDING_SM};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    display: none;
  }
`

const NetworkText = styled(Copy)<{ underColor: string }>`
  border-left: 1px solid ${({ underColor }) => underColor};
  padding: ${styles.PADDING_XXS};
  padding-left: ${styles.PADDING_SM};
  font-size: 1em;
`

const HoverContainer = styled.div`
  display: flex;
  position: absolute;
  top: 70px;
  right: 0;
  flex-direction: column;
  z-index: 5;
  margin-right: ${styles.PADDING_MD};
  filter: ${styles.dropShadow};
  min-width: 200px;
  &:hover {
    cursor: pointer;
  }
`

const MainDrop = styled.div<{ selected: boolean }>`
  display: flex;
  padding: ${styles.PADDING_XS};
  padding-bottom: 0;
  color: ${({ theme }) => theme.GREEN};
  cursor: pointer;
`

const Drop = styled.div<{ color: string; selected: boolean }>`
  display: flex;
  font-family: 'Raleway', 'Gill Sans MT', Calibri, sans-serif;
  background-color: ${({ selected, theme }) =>
    selected ? theme.DARK4 : theme.DARK4};
  border-left: 1px solid ${({ color }) => color};
  border-bottom: 1px solid ${({ theme }) => theme.DARK3};
  padding: ${styles.PADDING_MD};
  font-weight: 300;
  color: ${({ theme }) => theme.DARK1};
  &:hover {
    background-color: ${({ theme }) => theme.DARK};
    color: ${({ theme }) => theme.DARK1};
    cursor: pointer;
  }

  &:visited {
    color: ${({ theme }) => theme.DARK1};
  }
`

const ALink = styled.a`
  text-decoration: none;
  &:visited {
    color: ${({ theme }) => theme.DARK1};
  }
`
const Nav = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.DARKER};
`
const triSize = '20px'

const Triangle = styled.div`
  align-self: flex-end;
  width: 0;
  height: 0;
  border-left: ${triSize} solid transparent;
  border-right: ${triSize} solid transparent;
  border-bottom: ${triSize} solid ${({ theme }) => theme.DARKER}; ;
`

const networkNames = (colors, currentNetwork: string) => [
  {
    name: 'NEAR Mainnet',
    subdomain: 'mainnet.',
    color: colors.GREEN,
    url: 'https://mintbase.io',
    isActive: currentNetwork === 'mainnet',
  },
  {
    name: 'NEAR Testnet',
    subdomain: 'testnet.',
    color: colors.YELLOW,
    url: 'https://testnet.mintbase.io',
    isActive: currentNetwork === 'testnet' || currentNetwork === 'near',
  },
  {
    name: 'Ethereum',
    subdomain: 'eth.',
    color: colors.ACCENT,
    url: 'https://eth.mintbase.io',
    isActive: currentNetwork === 'ethereum',
  },
]

const ToggleNetworks = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [domain, setDomain] = useState(null)
  const [protocol, setProtocol] = useState('https:')
  const { colors, network } = useApp()
  const [networks, setNetworks] = useState([])
  useEffect(() => {
    if (domain && network) {
      setNetworks(
        networkNames(colors, network.network).map(
          ({ name, subdomain, isActive, color, url }) => ({
            name,
            url,
            isActive,
            color,
          })
        )
      )
    }
  }, [domain, network])

  if (typeof window !== undefined) {
    useEffect(() => {
      setProtocol(window.location.protocol)

      const parsedDomain = /:\/\/([^\/]+)/.exec(window.location.href)[1]

      if (parsedDomain.includes('.')) {
        setDomain(parsedDomain.split('.')[1])
        return
      }
      setDomain(parsedDomain.split('.')[0])
    }, [])
  }
  const selectedNetwork = networks.find(({ isActive }) => isActive === true)

  if (!selectedNetwork) {
    return null
  }

  return (
    <Container
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <MainDrop selected={true}>
        {networks.length > 0 && (
          <NetworkText size="1em" underColor={selectedNetwork.color}>
            {selectedNetwork.name}
          </NetworkText>
        )}
      </MainDrop>

      <AnimatePresence initial={false}>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <HoverContainer>
              <Triangle />
              <Nav>
                {networks.map(({ name, url, isActive, color }, i) => (
                  <Link href={url} passHref key={name}>
                    <ALink>
                      <Drop color={color} selected={isActive}>
                        {name}
                      </Drop>
                    </ALink>
                  </Link>
                ))}
              </Nav>
            </HoverContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default ToggleNetworks
