import styled from 'styled-components'

export const CONTENT_TYPE_TEXT = 'CONTENT_TYPE_TEXT'
export const CONTENT_TYPE_IMG = 'CONTENT_TYPE_IMG'
export const CONTENT_TYPE_BUTTON = 'CONTENT_TYPE_BUTTON'

export const LOGO = 'Raleway'

const FONT_SARIF = 'Raleway'
export const Thin = styled.div`
  font-family: 'Raleway', Calibri, sans-serif;
  font-weight: 100;
`

export const Gill = styled.div`
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  font-weight: 200;
`

export const GillBold = styled.div`
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  font-weight: 200;
`

export const LatoLight = styled.div`
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  /* font-family: 'Lato', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif; */
  font-weight: 100;
  letter-spacing: 1px;
`

export const Normal = styled.div`
  font-family: ${FONT_SARIF}, serif;
  font-weight: 300;
`
