import React, { useState, useEffect } from 'react'
import {
  useApp,
  useDispatchApp,
  SET_PROVIDER,
  SET_WALLET_CONNECT,
  SET_PURCHASE_ITEM,
  SET_WALLET_MINTBASEJS,
} from './AppContext'
import { useSpring, animated } from 'react-spring'
import * as styles from '../styles'
import styled from 'styled-components'
import { Header1, Copy } from './Typography'
import { WideButton } from './Buttons'
import * as paths from '../content/paths'
import { CoolBox } from '../utils/annimations'
import useTranslation from 'next-translate/useTranslation'
import { Wallet, Network, Chain } from 'mintbase'
import { NetworkConnection } from 'types/base'
import useFirebaseCustomAuth from 'utils/auth/useFirebaseCustomAuth'

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${styles.PADDING_LG};
  max-width: 700px;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

export const MintButtons = styled.div`
  display: flex;
  flex-grow: 1;
`

export const Container: any = styled(CoolBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  flex-grow: 1;
  z-index: 6;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.DARK_ALPHA};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
    align-items: center;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.DARK3};
  position: relative;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
`

export const CloseButton = styled.div`
  position: absolute;
  right: ${styles.PADDING_XS};
  top: ${styles.PADDING_XS};
  text-decoration: none;
  color: ${({ theme }) => theme.DARK};
  font-size: 1.6em;
  &:hover {
    cursor: pointer;
  }
`

const Terms = styled.div`
  display: flex;
  color: ${({ theme }) => theme.DARK2};
`

const TermsLink = styled.a`
  color: ${({ theme }) => theme.DARK3};
  padding: ${styles.PADDING_XXS};
  text-align: letf;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.DARK};
    cursor: pointer;
  }
`

interface NeedsContent {
  title: string
  copy: string
}

const getContent = (): NeedsContent | null => {
  return {
    title: 'Connect to Wallet',
    copy: "By connecting to a wallet, you are agreeing to both Mintbase's",
  }
}

const Wrapper = styled(animated.div)`
  position: fixed;
  transform-origin: top left;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
`

const ConnectWallet = ({ network }: { network: NetworkConnection }) => {
  const { screenHeight, colors, wallet, needsWallet } = useApp()
  const { t } = useTranslation('common')

  const dispatch = useDispatchApp()
  const { signIn } = useFirebaseCustomAuth()

  const [isOpen, setIsOpen] = useState(false)

  const initWallet = async (newNetwork: NetworkConnection) => {
    const apiKey = process.env.NEXT_PUBLIC_MINTBASEJS_API_KEY
    const chain = Chain.near
    const networkName = newNetwork?.networkName || Network.testnet

    const { data: walletData, error } = await new Wallet().init({
      networkName,
      chain,
      apiKey,
    })

    const { wallet } = walletData

    if (error) return console.error(error)
    if (!wallet) return

    dispatch({
      type: SET_WALLET_MINTBASEJS,
      payload: { wallet },
    })

    await signIn(wallet, networkName)
  }

  useEffect(() => {
    if (network) {
      dispatch({
        type: SET_PROVIDER,
        payload: { network },
      })
    }
  }, [network])

  const handleNearLogin = async (required?: boolean) => {
    if (!wallet) {
      initWallet(network)
      return
    }

    await wallet.connect({ requestSignIn: required || false })

    if (wallet.isConnected()) {
      const { data: details } = await wallet.details()

      const account = details.accountId

      dispatch({
        type: SET_PROVIDER,
        payload: {
          account: account,
          wallet: wallet,
          walletDetails: {
            balance: details.balance,
            allowance: details.allowance,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (network) {
      initWallet(network)
    }
  }, [network])

  useEffect(() => {
    if (wallet) {
      handleNearLogin(false)
    }
  }, [wallet])

  const styleProps = useSpring({
    transform: isOpen
      ? `translate(0px, 0px)`
      : `translate(0px, ${screenHeight || -2000}px)`,
    opacity: isOpen ? 1 : 0,
    config: {
      mass: 1,
      tension: 140,
    },
  })

  useEffect(() => {
    if (needsWallet) {
      setIsOpen(true)
      return
    }

    setIsOpen(false)
  }, [needsWallet])

  const needsContent = getContent()

  return (
    <Wrapper style={styleProps}>
      <Container isOpen={isOpen}>
        <FormContainer>
          <CloseButton
            onClick={() => {
              dispatch({
                type: SET_WALLET_CONNECT,
                payload: { contract: null, needsWallet: false },
              })
              dispatch({
                type: SET_PURCHASE_ITEM,
                payload: { payByCard: false, purchaseItem: null },
              })
            }}
          >
            <styles.CloseX size={40} />
          </CloseButton>
          <FormContent>
            <Header1>{needsContent.title}</Header1>
            <Copy>{needsContent.copy}</Copy>
            <Terms>
              <TermsLink href={paths.PRIVACY_URL} target="_blank">
                <Copy color={colors.DARK2}>Privacy + Terms Policy</Copy>
              </TermsLink>
            </Terms>
          </FormContent>

          <MintButtons>
            <MintButtons>
              <WideButton
                onClick={() => handleNearLogin(true)}
                background={colors.DARK2}
              >
                {t`connect-wallet`}
              </WideButton>
            </MintButtons>
          </MintButtons>
        </FormContainer>
      </Container>
    </Wrapper>
  )
}

export default ConnectWallet
