import { useEffect } from 'react'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'
import { useDispatchApp, SET_STORES, useApp } from './AppContext'
import * as styles from '../styles'

export const GET_STORES = gql`
  query GetMinterStores($id: String!) {
    store(
      where: { minters: { account: { _eq: $id } } }
      order_by: { createdAt: desc }
    ) {
      id
      name
      createdAt

      things(where: { tokens: { burnedAt: { _is_null: true } } }, limit: 1) {
        id
        metaId
      }
    }
  }
`

interface StoresProps {
  accountId?: string | string[]
}
const Stores = () => {
  const dispatch = useDispatchApp()
  const { account, user } = useApp()

  const [fetchStores, { loading, error, data }] = useLazyQuery(GET_STORES)

  useEffect(() => {
    if (account && user) {
      fetchStores({
        variables: { id: account.toString() },
      })
    }
  }, [account, user])

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_STORES,
        payload: data.store.map((store) => store),
      })
    }
  }, [data])

  return <div />
}

export default Stores
