import { useApp, useDispatchApp, TOGGLE_DOOR } from './AppContext'
import styled from 'styled-components'
import * as styles from '../styles'
import { useSpring, animated } from 'react-spring'

const Container = styled.div`
  display: flex;
`

const Wrapper: any = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.DARK_ALPHA};
  transform-origin: top left;
  z-index: 5;
`

const Screen = () => {
  const { navOpen } = useApp()
  const dispatch = useDispatchApp()
  const styleProps = useSpring({
    transform: navOpen ? `scaleX(1)` : `scaleX(0)`,
    opacity: navOpen ? 1 : 0.4,
  })

  return (
    <Wrapper style={styleProps} onClick={() => dispatch({ type: TOGGLE_DOOR })}>
      <Container></Container>
    </Wrapper>
  )
}

export default Screen
