export const OFF_WHITE: 'rgb(0, 0, 0)' = 'rgb(0, 0, 0)'
export const ALWAYS_WHITE: 'rgba(255, 255, 255, 1)' = 'rgba(255, 255, 255, 1)'
export const DROP_SHADOW: 'drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.2));' =
  'drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.2));'

export const DARK4_95: 'rgba(246, 246, 246, 0.95)' = 'rgba(246, 246, 246, 0.95)'

export const DARKER = 'rgba(255, 255, 255, 1)'
export const DARK4 = 'rgba(246, 246, 246, 1)'
export const DARK: 'rgba(233, 233, 233, 1)' = 'rgba(233, 233, 233, 1)'
export const DARK3 = 'rgba(200, 200, 200, 1)'
export const DARK2 = 'rgba(132, 130, 130, 1)'
export const DARK1 = 'rgba(23, 26, 28, 1)'

export const DARK_ALPHA: 'rgba(255, 255, 255, 0.8)' = 'rgba(255, 255, 255, 0.8)'
export const BLACK: 'rgb(0, 0, 0)' = 'rgb(0, 0, 0)'

export const ACCENT: 'rgba(202, 62, 41, 1)' = 'rgba(202, 62, 41, 1)'
export const RED_ERROR: 'rgba(144, 29, 29, 1)' = 'rgba(144, 29, 29, 1)'
export const GREEN: 'rgb(0, 0, 0)' = 'rgb(0, 0, 0)'
export const YELLOW: 'rgba(246, 255, 0, 1)' = 'rgba(246, 255, 0, 1)'
