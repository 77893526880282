import useTranslation from 'next-translate/useTranslation'
import * as paths from 'content/paths'

interface HumanMenuItem {
  url: string
  name: string
}

export interface MenuProps {
  uid: string
  children: (item: HumanMenuItem) => JSX.Element
}

// TODO: [migration] rename uid to walletAddress
// also we can fetch the walletAddress directly from app context
// instead of prop drilling

export const HumanMenu: React.FC<MenuProps> = ({
  uid: walletAddress,
  children,
}) => {
  const { t } = useTranslation('humanMenu')

  const menuItems: HumanMenuItem[] = [
    { url: `/human/${walletAddress}`, name: t`profile` },
    { url: `/settings`, name: t`settings` },
    { url: `/notifications`, name: t`notifications` },
    { url: `/accounts`, name: t`accounts` },
    { url: `/earned`, name: t`earned` },
    { url: `/orders`, name: t`open-orders` },
    { url: `/human/${walletAddress}/owns`, name: t`owns` },
    { url: `/developer`, name: t`developer` },
  ]

  return <>{menuItems.map(children)}</>
}

export const ResourcesMenu: React.FC<MenuProps> = ({ uid, children }) => {
  const { t } = useTranslation('humanMenu')

  const menuItems: HumanMenuItem[] = [
    { url: paths.DOCS, name: 'Docs' },
    { url: paths.DEVELOPERS, name: 'Developers' },
    { url: paths.COMMUNITY, name: 'Community' },
    { url: paths.CONTRACT, name: 'Store' },
    { url: paths.CAREERS, name: 'Careers' },
    { url: paths.ROYALTIES, name: 'Royalties' },
    { url: paths.DATA, name: 'Data' },
  ]

  return <>{menuItems.map(children)}</>
}
