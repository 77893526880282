import React, { useContext } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useSpring, animated } from 'react-spring'
import { useApp, useDispatchApp, SET_ERROR } from './AppContext'
import { Copy } from './Typography'
import { Button } from './Buttons'
import * as styles from '../styles'

interface ErrorProps {
  showError: boolean
  errorHeader: string | null
  status: string | null
  code: string | null
  name: string | null
  message: string | null
  hideError: () => void
}

const ErrorContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  filter: ${styles.DROP_SHADOW};
  background-color: ${({ theme }) => theme.DARK};
  z-index: 204;
`
const FormContent = styled.form`
  display: flex;
  flex-grow: 5;
  flex-direction: column;
  padding: ${styles.PADDING_MD};
  background-color: ${({ theme }) => theme.DARK4};
  border-bottom: 1px solid ${({ theme }) => theme.DARK3};
`

const ExitButton = styled(Button)`
  flex-grow: 1;
  max-width: 200px;
  color: ${({ theme }) => theme.DARKER};
  background-color: ${({ theme }) => theme.DARK2};
  /* color: ${({ theme }) => theme.OFF_WHITE}; */
`

const LinkButton = styled(Button)`
  flex-grow: 1;
  max-width: 200px;
  color: ${({ theme }) => theme.DARKER};
  background-color: ${({ theme }) => theme.DARK1};
`

const Wrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
`

const QUERY_PARAMS_REMOVE = [
  'errorCode',
  'errorMessage',
  'transactionHashes',
  'meta',
]

const ErrorModel = () => {
  const { errorMessage, errorUrl } = useApp()
  const dispatch = useDispatchApp()

  const styleProps = useSpring({
    transform:
      errorMessage !== null ? 'translate(0px, 0px)' : 'translate(0px, -200px)',
  })

  const handleExit = () => {
    dispatch({ type: SET_ERROR, payload: null })

    const url = new URL(window.location.href)

    QUERY_PARAMS_REMOVE.forEach((param) => {
      url.searchParams.delete(param)
    })

    window.history.pushState({}, '', url.toString())
  }

  return (
    <Wrapper style={styleProps}>
      <ErrorContainer>
        <FormContent>
          <Copy>{errorMessage}</Copy>
        </FormContent>
        {!!errorUrl && (
          <Link href={errorUrl}>
            <LinkButton>See more</LinkButton>
          </Link>
        )}
        <ExitButton onClick={handleExit}>Got It</ExitButton>
      </ErrorContainer>
    </Wrapper>
  )
}

export default ErrorModel
