
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import Head from 'next/head'
import { useEffect } from 'react'
import App from 'next/app'
// import '../styles.css'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import ConnectWallet from '../components/ConnectWallet'
import { AppProvider } from 'components/AppContext'
import * as paths from 'content/paths'
import Maintenance from 'components/Maintenance'
import DesktopNav from '../components/DesktopNav'
import CreateStore from '../components/CreateStore'
import Loader from '../components/Loader'
import ErrorModel from '../components/ErrorModel'
import MessageModal from '../components/MessageModal'
import CroppingModal from '../components/CroppingModal'
import styled, { createGlobalStyle } from 'styled-components'
import * as styles from '../styles'
import TransferModel from '../components/TransferModal'
import TopNav from '../components/TopNav'
import Screen from '../components/Screen'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'services/apolloClient'
import * as gtag from 'utils/gtag'
import { getNetworkDetails } from 'utils/network'
import ComplianceAlert from 'components/ComplianceAlert'

declare global {
  interface Window {
    web3: any
    GA_INITIALIZED: boolean
    Box: any
    Mintbase: any
    near: any
    walletAccount: any
    gtag: any
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0 auto;
    background-color:${styles.DARK};
  }
`

const themeMat = createMuiTheme({
  palette: {
    primary: {
      main: styles.DARK2,
    },
  },
})

const AppHolder = styled.div`
  display: flex;
  flex: 1 0 0;
  background-color: ${({ theme }) => theme.DARK};

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const MyApp = (obj) => {
  const isInMaintenance = false

  const { Component, pageProps, router } = obj

  const apolloClient = useApollo(pageProps)

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <AppProvider>
      <Head>
        <title>Mintbase</title>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      </Head>
      <GlobalStyle />
      <ApolloProvider client={apolloClient}>
        <MuiThemeProvider theme={themeMat}>
          {!isInMaintenance && (
            <AppHolder>
              <Loader />
              <CroppingModal />
              <ConnectWallet network={pageProps.network} />
              <ErrorModel />
              <MessageModal timeout={3000} />
              <CreateStore />
              <TransferModel />
              <Container>
                <TopNav />
                <DesktopNav {...pageProps} />
                {!isInMaintenance && <Component {...pageProps} />}

                <Screen />
              </Container>
            </AppHolder>
          )}
          {isInMaintenance && <Maintenance {...pageProps} />}
          <ComplianceAlert />
        </MuiThemeProvider>
      </ApolloProvider>
    </AppProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)
  const network = getNetworkDetails(appContext?.ctx?.req?.headers)
  return { ...appProps, pageProps: { network } }
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  