import { gql } from 'apollo-boost'

export const GET_TOP_AUCTIONS = gql`
  query getTopAuctions {
    markets(
      where: { isTopAuction: { _eq: true } }
      order_by: { createdAt: desc }
      limit: 4
    ) {
      id
      createdAt
      createdBy
      thingId
      thing {
        id
        metaId
        store {
          id
        }
        metadata {
          title
          description
          media
          media_hash
          animation_hash
          animation_url
          youtube_url
          document
          document_hash
          extra
          external_url
          category
          type
          visibility
          media_type
          animation_type
          tags
          media_size
          animation_size
        }
      }
      list {
        price
        groupId
        ownerId
        autotransfer
        createdAt
        token {
          splits {
            id
            account
            txId
          }
          royaltys {
            id
            account
            txId
          }
        }
      }
    }
  }
`

export const GET_TOP_STORES = gql`
  query getTopStores {
    markets(
      where: { isTopStore: { _eq: true } }
      order_by: { createdAt: desc }
      limit: 4
    ) {
      id
      createdAt
      createdBy
      thing {
        id
        metaId
        store {
          id
        }
        metadata {
          title
          description
          media
          media_hash
          animation_hash
          animation_url
          youtube_url
          document
          document_hash
          extra
          external_url
          category
          type
          visibility
          media_type
          animation_type
          tags
          media_size
          animation_size
        }
      }
      list {
        price
        groupId
        ownerId
        autotransfer
        createdAt
        token {
          splits {
            id
            account
            txId
          }
          royaltys {
            id
            account
            txId
          }
        }
      }
    }
  }
`

export const GET_OTHER_STORE_THINGS = gql`
  query getOtherStoreItems($id: String!) {
    store(where: { id: { _eq: $id } }) {
      name
      owner
      createdAt
      things(where: { tokens: { burnedAt: { _is_null: true } } }, limit: 4) {
        id
        metaId
      }
    }
  }
`

export const GET_LATEST_LIST = gql`
  query getListedItemsTree($groupId: String!) {
    list(where: { groupId: { _eq: $groupId } }, limit: 1) {
      createdAt
      price
      tokenKey
      groupId
      acceptedOfferId
      removedAt
      ownerId
      token {
        id
        thingId
        minter
        ownerId
        royaltyPercent
        thing {
          metaId
        }
        splits {
          account
          percent
        }
        royaltys {
          account
          percent
        }
        store {
          id
          name
          owner
          createdAt
          things(limit: 5) {
            id
            metaId
          }
        }
      }
    }
  }
`

export const GET_MODS = gql`
  query tokens($id: String!, $token: String!) {
    tokens(where: { thingId: $token, resolveOwner: $id }) {
      id
    }
  }
`

export const SEARCH_STORES = gql`
  query stores($key: String!) {
    storeSearch(text: $key) {
      id
      name
      burned
      owner
      things(first: 1, where: { burned: false }) {
        metaId
      }
    }
  }
`

export const GET_AGGREGATES = gql`
  query getEverything {
    stores_aggregate {
      aggregate {
        count
      }
    }
    things_aggregate {
      aggregate {
        count
      }
    }
    tokens_aggregate {
      aggregate {
        count
      }
    }
    minters_aggregate {
      aggregate {
        count
      }
    }
    lists_aggregate {
      aggregate {
        count
      }
    }
    offers_aggregate(where: { acceptedAt: { _is_null: false } }) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`

export const GET_SOME_STORES = gql`
  query stores($more: Int!, $skip: Int!) {
    stores(
      where: { burned: false }
      orderBy: createdAt
      orderDirection: desc
      skip: $skip
      first: $more
    ) {
      id
      name
      burned
      owner
      things(first: 1, where: { burned: false }) {
        metaId
        tokens {
          id
          price
          state
          tokenId
        }
      }
    }
  }
`

export const GET_STORE = gql`
  query myThing($id: String!) {
    thing(where: { storeId: { _eq: $id } }) {
      burned
      forSale
      id
      metaId
      minter
      storeId
      store {
        id
        name
        symbol
      }
      tokens(where: { burned: { _eq: false } }) {
        id
        state
        price
        tokenId
      }
    }
  }
`

export const GET_STORE_AND_LISTED_TOKENS = gql`
  query GET_STORE_AND_OWNED_TOKENS($id: String!) {
    store(where: { id: { _eq: $id } }) {
      name
      owner
      createdAt
      things {
        burned
        forSale
        id
        metaId
        minter
        storeId
        store {
          id
          name
          symbol
          owner
        }
        tokens(where: { burned: { _eq: false } }) {
          id
          state
          price
          tokenId
          ownerId
        }
      }
    }
  }
`

export const GET_STORE_AND_OWNED_TOKENS = gql`
  query GET_STORE_AND_OWNED_TOKENS($id: String!, $account: String!) {
    store(where: { id: { _eq: $id } }) {
      name
      owner
      createdAt
      things {
        burned
        forSale
        id
        metaId
        minter
        storeId
        store {
          id
          name
          symbol
          owner
        }
        tokens(
          where: {
            burned: { _eq: false }
            _and: { ownerId: { _eq: $account } }
          }
        ) {
          id
          state
          price
          tokenId
          ownerId
        }
      }
    }
  }
`

export const GET_STORE_AND_OWNED_TOKENS_2 = gql`
  query GET_STORE_AND_OWNED_TOKENS($id: String!, $account: String!) {
    thing(where: { storeId: { _eq: $id } }) {
      burned
      forSale
      id
      metaId
      minter
      storeId
      store {
        id
        name
        symbol
        owner
      }
      tokens(
        where: { burned: { _eq: false }, _and: { ownerId: { _eq: $account } } }
      ) {
        id
        state
        price
        tokenId
      }
    }
  }
`

export const GET_STORE_SETTINGS = gql`
  query myStore($id: String!) {
    store(where: { name: { _eq: $id } }) {
      id
      name
      symbol
      minters {
        id
        account
        enabled
      }
    }
  }
`

export const GET_MINTBASE_STATS = gql`
  query mintbases {
    mintbases {
      boughtCount
      storeCount
      tokenCount
      transferCount
      valueCount
    }
  }
`

export const GET_STORE_NAME = gql`
  query store($id: String!) {
    store(id: $id) {
      id
      name
      symbol
      owner
    }
  }
`

export const GET_STORE_BASIC = gql`
  query store($id: String!, $approver: String!) {
    store(id: $id, where: { burned: false }) {
      id
      name
      symbol
      owner
      approvers(id: $approver) {
        id
      }
    }
  }
`

export const GET_ONE_STORE = gql`
  query store($id: String!) {
    store(id: $id, where: { burned: false }) {
      id
      name
      symbol
      totalSupply
      owner
      things(where: { burned: false }) {
        id
        burned
        forSale
        metaId
        tokens(first: 1000) {
          id
          price
          state
          tokenId
        }
      }
    }
  }
`

export const GET_MARKET = gql`
  query store($id: String!) {
    store(id: $id, where: { burned: false }) {
      id
      name
      symbol
      totalSupply
      owner
      things(where: { burned: false }) {
        id
        burned
        forSale
        metaId
        tokens(first: 1000, where: { state: "1" }) {
          id
          price
          state
          tokenId
        }
      }
    }
  }
`

export const GET_STORE_PREVIEW = gql`
  query stores($id: String!) {
    store(where: { id: { _eq: $id } }) {
      id
      name
      symbol
      totalSupply
      owner
      createdAt
      things(limit: 4) {
        id
        forSale
        metaId
        tokens {
          id
          price
          state
          tokenId
        }
      }
    }
  }
`

export const GET_USER_TOKENS = gql`
  query tokens($userId: String!, $store: String!) {
    tokens(where: { resolveOwner: $userId, store: $store, burned: false }) {
      id
      tokenId
      metaId
      state
      store {
        name
      }
    }
  }
`

export const GET_THINGS = gql`
  query store($id: String!) {
    store(id: $id) {
      id
      name
      symbol
      totalSupply
      things(first: 1000, where: { burned: false }) {
        id
        burned
        forSale
        metaId
        tokens {
          id
          price
          state
          tokenId
        }
      }
    }
  }
`

export const HAS_THING_FROM_STORE = gql`
  query tokens($ownerId: String!, $metaId: String!, $storeId: String!) {
    tokens(
      where: { metaId: $metaId, store: $storeId, resolveOwner: $ownerId }
    ) {
      id
    }
  }
`

export const HAS_TOKEN = gql`
  query HasToken($ownerId: String!, $thingId: String!) {
    token(
      where: { ownerId: { _eq: $ownerId }, thing: { id: { _eq: $thingId } } }
    ) {
      id
    }
  }
`

export const GET_THING = gql`
  query thing($metaId: String!) {
    thing(where: { id: { _eq: $metaId } }) {
      id
      burned
      forSale
      metaId
      minter
      store {
        id
        burned
      }
      tokens {
        id
        price
        state
        tokenId
      }
    }
  }
`

export const GET_ALL_THINGS = gql`
  query GetThing {
    things(limit: 30, order_by: { createdAt: asc }) {
      id
      minter
      forSale
      metaId
      tokens {
        id
      }
      store {
        id
        name
      }
    }
  }
`

export const GET_LOADED_THINGS = gql`
  query things($more: Int!, $skip: Int!) {
    things(
      where: { burned: false }
      orderBy: createdAt
      orderDirection: desc
      skip: $skip
      first: $more
    ) {
      id
      minter
      forSale
      storeId {
        id
      }
    }
  }
`

export const GET_ALL_STORES = gql`
  query stores($more: Int!, $skip: Int!) {
    stores(
      where: { burned: { _eq: true } }
      orderBy: createdAt
      orderDirection: desc
      skip: $skip
      first: $more
    ) {
      id
      name
      burned
      owner

      things(first: 1, where: { burned: false }) {
        metaId
      }
    }
  }
`

export const GET_USER_THINGS = gql`
  query token($account: String!) {
    token(
      where: { ownerId: { _eq: $account } }
      order_by: { createdAt: desc }
      limit: 30
    ) {
      id
      metaId
      state
      price
      ownerId
      tokenId
      store {
        id
        burned
        owner
      }
      royaltys {
        percent
        account
      }
      splits {
        percent
        account
      }
    }
  }
`

export const GET_LIMITED_USER_THINGS = gql`
  query getThingsLimited($limit: Int!) {
    thing(limit: $limit, order_by: { createdAt: asc }) {
      id
      metaId
      minter
    }
  }
`

export const GET_LIMITED_USER_THINGS_BY_MINTER = gql`
  query getThingsLimited($limit: Int!, $minter: String!) {
    thing(
      limit: $limit
      order_by: { createdAt: asc }
      where: { minter: { _eq: $minter } }
    ) {
      id
      metaId
      minter
    }
  }
`

export const GET_LIMITED_USER_THINGS_BY_MINTERS = gql`
  query getThingsLimited($minters: [String!]) {
    token(
      order_by: { createdAt: asc }
      where: { minter: { _in: $minters }, burnedAt: { _is_null: true } }
      limit: 20
    ) {
      id
      thingId
      thing {
        id
        metaId
      }
    }
  }
`

export const GET_MINTED_THINGS_BY_MINTER_ID = gql`
  query GetMintedThingsByMinterId($minter: String!) {
    token(
      order_by: { thingId: asc }
      where: { minter: { _eq: $minter }, burnedAt: { _is_null: true } }
      limit: 20
      distinct_on: thingId
    ) {
      id
      thingId
      thing {
        id
        metaId
      }
    }
  }
`

export const GET_USER_STORE = gql`
  query getStore($id: String!) {
    user(id: $id) {
      stores(where: { burned: false }) {
        id
        burned
      }
    }
  }
`

export const GET_TOKEN = gql`
  query token($id: String!) {
    token(id: $id) {
      metaId
      price
    }
  }
`

export const GET_STORE_OWNER = gql`
  query store($id: String!) {
    store(id: $id, where: { burned: false }) {
      id
      name
      symbol
      totalSupply
      owner
      createdAt
    }
  }
`

export const GET_INDEX = gql`
  query getIndex {
    indexer(where: { id: { _eq: 1 } }) {
      syncedBlock
    }
  }
`
