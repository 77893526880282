import styled from 'styled-components'
import { useApp, useDispatchApp, ACCEPT_GDPR } from './AppContext'
import * as styles from 'styles'
import { Copy } from './Typography'
import * as paths from 'content/paths'
import { useEffect, useState } from 'react'
import { BlankA } from './Buttons'
import { CheckCircle } from '@styled-icons/feather/CheckCircle'

const Container = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.DARK2};
  border: 1px solid ${({ theme }) => theme.DARK3};
  bottom: ${styles.PADDING_SM};
  left: ${styles.PADDING_SM};
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.3);
  padding: ${styles.PADDING_XXS};
  display: flex;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Block = styled.div`
  display: block;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${styles.PADDING_MD};
`

const ABit = styled(BlankA)`
  color: ${({ theme }) => theme.ACCENT};
  /* border-bottom: 1px solid ${({ theme }) => theme.GREEN}; */
`

const Text = styled(Copy)`
  color: ${({ theme }) => theme.DARK4};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 0.9em;
  }
`

const Button = styled.div`
  background-color: ${({ theme }) => theme.DARKER};
  padding: ${styles.PADDING_SM};
  margin: ${styles.PADDING_SM};
  border: 1px solid ${({ theme }) => theme.GREEN};
  color: ${({ theme }) => theme.GREEN};
  &:hover {
    background-color: ${({ theme }) => theme.OFF_WHITE};
    color: ${({ theme }) => theme.DARKER};
    cursor: pointer;
  }
`

const Private = styled(Copy)`
  color: ${({ theme }) => theme.GREEN};
  border-bottom: 1px solid ${({ theme }) => theme.GREEN};
  padding: ${styles.PADDING_XS};
`

const ComplianceAlert = () => {
  const { colors } = useApp()

  const [acceptedGDPR, setAcceptedGDPR] = useState(true)

  if (typeof window !== 'undefined') {
    useEffect(() => {
      const accepted = localStorage.getItem('gdpr')
      if (accepted === 'true') {
        return setAcceptedGDPR(true)
      }
      setAcceptedGDPR(false)
    }, [])
  }

  const handleAccept = () => {
    localStorage.setItem('gdpr', 'true')
    setAcceptedGDPR(!acceptedGDPR)
  }

  if (acceptedGDPR) {
    return null
  }

  return (
    <Container>
      <Content>
        <Text>By using Mintbase, {acceptedGDPR} you are agreeing to our</Text>
        <Block>
          <ABit target="_blank" href={paths.PRIVACY_URL}>
            <Private color={colors.OFF_WHITE} size="1.1rem">
              privacy policy
            </Private>
          </ABit>
        </Block>
      </Content>

      <Button onClick={handleAccept}>
        <CheckCircle size={30} />
      </Button>
    </Container>
  )
}

export default ComplianceAlert
