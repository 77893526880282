export const OFF_WHITE: 'rgba(255, 255, 255, 1)' = 'rgba(255, 255, 255, 1)'
export const ALWAYS_WHITE: 'rgba(255, 255, 255, 1)' = 'rgba(255, 255, 255, 1)'
export const ALWAYS_DARK: 'rgba(13, 16, 18, 1)' = 'rgba(13, 16, 18, 1)'
export const DROP_SHADOW: 'drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.2));' =
  'drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.2));'

export const DARK1: 'rgba(226, 226, 226, 1)' = 'rgba(226, 226, 226, 1)'
export const DARK2: 'rgba(140, 139, 136, 1)' = 'rgba(140, 139, 136, 1)'
export const DARK3: 'rgba(54, 54, 54, 1)' = 'rgba(54, 54, 54, 1)'
export const DARK4: 'rgba(23, 26, 28, 1)' = 'rgba(23, 26, 28, 1)'
export const DARK4_95: 'rgba(23, 26, 28, 0.95)' = 'rgba(23, 26, 28, 0.95)'
export const DARKER: 'rgba(13, 16, 18, 1)' = 'rgba(13, 16, 18, 1)'
export const DARK: 'rgba(34, 38, 41, 1)' = 'rgba(34, 38, 41, 1)'

export const DARK_ALPHA: 'rgba(23, 26, 28, 0.8)' = 'rgba(23, 26, 28, 0.8)'

export const BLACK: 'rgb(0, 0, 0)' = 'rgb(0, 0, 0)'

// export const ACCENT: 'rgba(93, 212, 145, 1)' = 'rgba(93, 212, 145, 1)'
// export const RED_ERROR: 'rgba(144, 29, 29, 1)' = 'rgba(144, 29, 29, 1)'
// export const GREEN: 'rgba(93, 212, 145, 1)' = 'rgba(93, 212, 145, 1)'

export const ACCENT: 'rgba(202, 62, 41, 1)' = 'rgba(202, 62, 41, 1)'
export const RED_ERROR: 'rgba(144, 29, 29, 1)' = 'rgba(144, 29, 29, 1)'
export const GREEN: 'rgba(93, 212, 145, 1)' = 'rgba(93, 212, 145, 1)'
export const YELLOW: 'rgba(178, 204, 0, 1)' = 'rgba(178, 204, 0, 1)'
