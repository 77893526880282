import styled from 'styled-components'
// import TextField from '@material-ui/core/TextField'
import * as styles from '../styles'

interface TypeProps {
  size?: string
  color?: string
  bottom?: string
  top?: string
  lineHeight?: string
}

export const HiddenInput = styled.input`
  display: hidden;
`

export const StepInput = styled.input`
  display: flex;
  border: 0;
  margin-top: ${styles.PADDING_MD};
  font-size: 1.6em;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  border-bottom: 1px solid ${({ theme }) => theme.DARK};
  background: transparent;
  color: ${({ theme }) => theme.DARK};
  flex-grow: 1;
  padding-bottom: ${styles.PADDING_XS};
  margin-bottom: ${styles.PADDING_SM};
  &:focus {
    outline: none;
  }
`

export const Logo = styled(styles.LatoLight)<TypeProps>`
  color: ${({ theme }) => theme.OFF_WHITE};
  font-size: ${({ size }) => size || '2.4em'};
  font-weight: 300;
  margin: 0;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 2.8em;
  }
`

export const SanHeader = styled(styles.LatoLight)<TypeProps>`
  font-size: ${({ size }) => size || '2em'};
  color: ${({ color, theme }) => color || theme.DARKER};
  margin-bottom: ${styles.PADDING_XS};
`
export const Headline = styled(styles.Thin)`
  width: 100%;
  color: ${({ theme }) => theme.OFF_WHITE};
  font-size: 2em;
  letter-spacing: 3px;
  margin-bottom: ${styles.PADDING_SM};
`

export const SubHeader = styled(styles.Gill)`
  font-size: ${({ size }: TypeProps) => size || '1.3em'};
  color: ${({ color, theme }) => color || theme.DARK2};
`

export const Input = styled.input`
  border: 0;
  margin-top: ${styles.PADDING_MD};
  font-size: 1.4em;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  border-bottom: 1px solid ${({ theme }) => theme.DARK};
  background: transparent;
  color: ${({ theme }) => theme.DARK};
  padding-bottom: ${styles.PADDING_XS};
  margin-bottom: ${styles.PADDING_SM};
  &:focus {
    outline: none;
  }
`

export const DarkInput = styled.input<{
  right?: string
  bottom?: string
  top?: string
}>`
  border: 0;
  font-size: 1.1em;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  border: 1px solid ${({ theme }) => theme.DARK3};
  background: ${({ theme }) => theme.DARKER};
  color: ${({ theme, color }) => color || theme.DARK1};
  padding: ${styles.PADDING_SM};
  margin-top: ${({ top }) => top || styles.PADDING_SM};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_SM};
  margin-right: ${({ right }) => right || 0};
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.DARK2};
    font-style: italic;
    opacity: 1; /* Firefox */
    font-size: 1em;
  }
`

export const DarkTextArea = styled.textarea<{
  right?: string
  bottom?: string
  top?: string
}>`
  border: 0;
  font-size: 1.1em;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  border: 1px solid ${({ theme }) => theme.DARK3};
  background: ${({ theme }) => theme.DARKER};
  color: ${({ theme, color }) => color || theme.DARK1};
  padding: ${styles.PADDING_SM};
  margin-top: ${({ top }) => top || styles.PADDING_SM};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_SM};
  margin-right: ${({ right }) => right || 0};
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.DARK2};
    font-style: italic;
    opacity: 1; /* Firefox */
    font-size: 1em;
  }
`

export const WideDarkInput = styled(DarkInput)`
  display: flex;
  width: 100%;
`

export const DarkMultiline = styled.textarea`
  border: 0;
  margin-top: ${styles.PADDING_MD};
  font-size: 1.1em;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  height: 80px;
  border: 1px solid ${({ theme }) => theme.DARK2};
  background: transparent;
  color: ${({ theme, color }) => color || theme.OFF_WHITE};
  padding: ${styles.PADDING_SM};
  margin-bottom: ${styles.PADDING_SM};
  margin-right: ${0};
  flex-grow: 1;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.DARK2};
    font-style: italic;
    opacity: 1; /* Firefox */
    font-size: 1em;
  }
`

export const Header1 = styled(styles.LatoLight)`
  font-size: ${({ size }: TypeProps) => size || '1.7rem'};
  color: ${({ color, theme }) => color || theme.DARK1};
  margin-top: ${({ top }) => top || styles.PADDING_XXS};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_XS};
  font-weight: 100;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: ${({ size }: TypeProps) => size || '1.9rem'};
  }
`

export const HeaderThin = styled(styles.LatoLight)`
  font-size: ${({ size }: TypeProps) => size || '1.8em'};
  color: ${({ color, theme }) => color || theme.DARK1};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_SM};
  font-weight: 100;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.9rem;
    font-weight: 300;
    margin-bottom: ${styles.PADDING_XS};
  }
`

export const Subheader1 = styled(styles.LatoLight)`
  font-size: ${({ size }: TypeProps) => size || '1.3em'};
  color: ${({ color }: TypeProps) => color || styles.DARK2};
  margin-bottom: ${styles.PADDING_SM};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: ${({ size }: TypeProps) => size || '1.3em'};
  }
`
export const Copy = styled(styles.Gill)<TypeProps>`
  font-size: ${({ size }) => size || '1.2em'};
  color: ${({ color, theme }) => color || theme.DARK1};
  margin-top: ${({ top }) => top || 0};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_SM};
  line-height: ${({ lineHeight }) => lineHeight || '1.3em'};

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.3rem;
    /* margin-bottom: 0; */
  }
`

export const List = styled.li<TypeProps>`
  font-size: ${({ size }) => size || '1.2em'};
  color: ${({ color, theme }) => color || theme.DARK1};
  margin-top: ${({ top }) => top || 0};
  margin-bottom: ${({ bottom }) => bottom || styles.PADDING_SM};
  line-height: ${({ lineHeight }) => lineHeight || '1.3em'};
  font-family: 'Raleway', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
  font-weight: 300;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.1em;
    /* margin-bottom: 0; */
  }
`

export const SmallerHeader = styled(styles.LatoLight)<TypeProps>`
  font-size: ${({ size }) => size || '1.2em'};
  color: ${({ color }) => color || styles.DARK2};
  margin-bottom: ${styles.PADDING_SM};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.2rem;
  }
`

export const SmallHeader = styled(styles.Gill)<TypeProps>`
  font-size: ${({ size }) => size || '1.8em'};
  color: ${({ color, theme }) => color || theme.DARK3};
  margin-bottom: ${styles.PADDING_XS};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.7em;
  }
`
